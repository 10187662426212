import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Loader from "./utils/Loader";
import { toast } from "react-toastify";
import { useGlobalContext } from "../states/Context";

const Verify = () => {
  const { getApiUrl } = useGlobalContext();
  const { token } = useParams();
  const [loading, setLoading] = useState(true);
  const [isVerified, setIsVerified] = useState(false);
  const verifyUser = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${getApiUrl()}api/v1/auth/verify`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ token }),
        }
      );
      const data = await response.json();
      if (data.success) {
        toast.success(data.message);
        setIsVerified(true);
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
    setLoading(false);
  };
  useEffect(() => {
    verifyUser();
  }, []);
  return (
    <div className="w-full relative bg-gray-900 mx-auto h-[100vh] flex items-center justify-center">
      <button className=" text-white absolute left-2 top-4">
        Your Logo Will be Added Here
      </button>

      <div className="flex flex-col justify-center h-5/6">
        {loading && <Loader />}
        {isVerified && (
          <Link
            to={"/login"}
            className="border-2 rounded-lg mx-auto  border-blue-400 px-4 py-1 mt-4 uppercase w-max text-blue-400 font-medium"
          >
            login
          </Link>
        )}
      </div>
    </div>
  );
};

export default Verify;
