import { useEffect, useState } from "react";
import AdminLayout from "./AdminLayout";
import { toast } from "react-toastify";
import Loader from "../utils/Loader";
import { Link } from "react-router-dom";
import { FaPencil, FaTrash } from "react-icons/fa6";
import { MdLibraryAdd } from "react-icons/md";
import { useGlobalContext } from "../../states/Context";

const Libraries = () => {
  const [loading, setLoading] = useState(false);
  const [libraries, setLibraries] = useState([]);
  const { getApiUrl } = useGlobalContext();
  const getDocs = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${getApiUrl()}api/v4/libraries`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem("auth-token"),
        },
      });
      const data = await response.json();
      if (!data.success) {
        toast.error(data.message);
      }
      setLibraries(data.libraries);
    } catch (error) {
      toast.error(error.message);
    }
    setLoading(false);
  };
  const deleteDoc = async (id) => {
    setLoading(true);
    try {
      const response = await fetch(`${getApiUrl()}api/v4/library`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem("auth-token"),
          "doc-id": id,
        },
      });
      const data = await response.json();
      if (!data.success) {
        toast.error(data.message);
      } else {
        toast.success(data.message);
        window.location.reload();
        setLibraries(data.docs);
      }
    } catch (error) {
      toast.error(error.message);
    }
    setLoading(false);
  };
  useEffect(() => {
    getDocs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AdminLayout>
      <section className="text-gray-400 body-font">
        <div className="container px-5 py-16 mx-auto">
          <div className="flex flex-col text-center w-full mb-8">
            <h1 className="text-2xl font-medium title-font mb-4 text-white">
              YOUR LIBRARIES
            </h1>
          </div>
          {loading ? (
            <Loader />
          ) : (
            <div className="flex flex-wrap -m-4 mt-8 gap-12 justify-center">
              {libraries.map((p, idx) => {
                return (
                  <div
                    key={p._id + idx}
                    className="p-4 border border-gray-500 rounded-md lg:w-1/4 md:w-1/2"
                  >
                    <div className="h-full flex flex-col items-center text-center">
                      <div className="w-full">
                        <h2 className="title-font font-medium text-lg text-white">
                          {p.title}
                        </h2>
                        <h3 className="text-gray-500 mb-3">{p.category}</h3>
                        <p className="mb-4">{p.description} </p>
                        <div className="flex justify-center gap-4">
                          {/* <Link
                            to={`/admin/docs/${p.slug}`}
                            className="text-gray-400"
                          >
                            <FaPencil className="text-xl" />
                          </Link> */}
                          <button
                            onClick={() => deleteDoc(p._id)}
                            className="text-gray-400"
                          >
                            <FaTrash className="text-lg" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          {!loading && libraries.length === 0 && (
            <div className="h-[40vh] w-full flex justify-center items-center">
              <Link
                to={"/admin/library/create"}
                className="flex gap-2 py-4 px-6 bg-gray-800 border border-white rounded-md items-center"
              >
                <MdLibraryAdd className="text-2xl" />
                <span>Add Library</span>
              </Link>
            </div>
          )}
        </div>
      </section>
    </AdminLayout>
  );
};

export default Libraries;
