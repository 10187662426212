import Header from "./Header";
import Footer from "./Footer";

// eslint-disable-next-line react/prop-types
const AppLayout = ({ children }) => {
  return (
    <div className="w-3/5 mx-auto">
      <Header />
      <div className="h-[80vh]">{children}</div>
      <Footer />
    </div>
  );
};

export default AppLayout;
