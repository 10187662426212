import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import AdminLayout from "./AdminLayout";
import Input from "../utils/Input";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Loader from "../utils/Loader";
import { useGlobalContext } from "../../states/Context";

const AddLibrary = () => {
  const {getApiUrl} = useGlobalContext();
  const [library, setLibrary] = useState({
    title: "",
    plan: "free",
  });
  const handleChange = (e) => {
    setLibrary({ ...library, [e.target.name]: e.target.value });
  };
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  const addLibrary = async (e) => {
    e.preventDefault();
    try {
      if (!selectedFile) return toast.error("Please select a file");
      if (!library.title) return toast.error("Please enter a title");
      setLoading(true);
      const formData = new FormData();
      formData.append("title", library.title);
      if (selectedFile) {
        formData.append("file", selectedFile);
      }

      formData.append("plan", library.plan);
      const response = await fetch(`${getApiUrl()}api/v4/library`, {
        method: "POST",
        headers: {
          token: localStorage.getItem("auth-token"),
        },
        body: formData,
      });
      const data = await response.json();
      if (data.success) {
        toast.success(data.message);
        setSelectedFile(null);
        setLibrary({
          title: "",
          plan: "free",
          category: "",
        });
      } else toast.error(data.message);
    } catch (error) {
      toast.error(error.message);
    }
    setLoading(false);
  };

  return (
    <AdminLayout className="relative">
      <div className="w-2/5 mx-auto py-20">
        <h2 className="text-xl font-medium text-center mb-8">Add Library</h2>
        {loading ? (
          <Loader />
        ) : (
          <form onSubmit={addLibrary} className="w-full mt-8">
            <div className="my-4">
              <label className="block mb-2 text-sm" htmlFor="category">
                Title
              </label>
              <Input
                type="text"
                id="title"
                hint={"title"}
                autoComplete="off"
                value={library.title}
                handler={handleChange}
              />
            </div>
            {/* <div className="my-4">
            <label className="block mb-2 text-sm" htmlFor="category">
              Category
            </label>
            <select
              id="category"
              label="category"
              name="category"
              value={library.category}
              onChange={handleChange}
              className={`dark-glassmorphism  outline-none rounded-lg text-sm py-4 px-4 w-full transition-all duration-300 focus:border-1 focus:border-blue-400`}
            >
              <option className="bg-gray-800" value={"--select--"}>
                -- Select Category --
              </option>
              {categories.map((category) => {
                return (
                  <option
                    className="bg-gray-800"
                    key={category}
                    value={category}
                  >
                    {category}
                  </option>
                );
              })}
            </select>
          </div> */}
            {/* <div className="my-4">
              <label className="block mb-2 text-sm" htmlFor="plan">
                Plan
              </label>
              <select
                id="plan"
                label="plan"
                name="plan"
                value={library.plan}
                onChange={handleChange}
                className={`dark-glassmorphism  outline-none rounded-lg text-sm py-4 px-4 w-full transition-all duration-300 focus:border-1 focus:border-blue-400`}
              >
                <option className="bg-gray-800" key={"plan"} value={"free"}>
                  Free
                </option>
                <option
                  className="bg-gray-800"
                  key={"premium"}
                  value={"premium"}
                >
                  Premium
                </option>
              </select>
            </div> */}
            <div className="my-4">
              <label className="block mb-2 text-sm" htmlFor="file">
                File
              </label>
              {selectedFile ? (
                <p>
                  File Selected{" "}
                  <span className="text-gray-400">{selectedFile.name}</span>
                </p>
              ) : (
                <Input
                  id="file"
                  label="file"
                  type={"file"}
                  handler={handleFileChange}
                />
              )}
            </div>
            <div className="mt-8">
              <Input
                classes={`font-bold !bg-blue-400 hover:!bg-blue-500 cursor-pointer`}
                type="submit"
                value={"Add Library"}
              />
            </div>
          </form>
        )}
      </div>
    </AdminLayout>
  );
};

export default AddLibrary;
