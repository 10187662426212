import { useEffect } from "react";
import Menu from "./Menu";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

// eslint-disable-next-line react/prop-types
const AdminLayout = ({ children }) => {
  const { user, loading } = useSelector((state) => state.account);
  const nav = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("auth-token")) return nav("/login");
    if (!loading) {
      if (user.role !== "admin") return nav(-1);
    }
  }, [loading, nav, user]);

  return (
    <div className="flex h-[100vh] w-full">
      <Menu />
      <div className="w-5/6 !bg-gray-800 overflow-y-scroll">{children}</div>
    </div>
  );
};

export default AdminLayout;
