import { toast } from "react-toastify";
import {
  USER_LOGGED_IN,
  GET_USER_SUCCESS,
  GET_USER_REQUEST,
  GET_USER_FAIL,
  USER_LOGGED_OUT,
} from "../Constants/UserConstant";

export const userActor = (user) => async (dispatch) => {
  dispatch({ type: USER_LOGGED_IN, payload: user });
};

export const logOutUser = () => async (dispatch) => {
  localStorage.removeItem("auth-token");
  dispatch({ type: USER_LOGGED_OUT });
};

export const getUserInfo = () => async (dispatch) => {
  try {
    dispatch({ type: GET_USER_REQUEST });
    // console.log(first)
    let url = "";
    if (
      window.location.hostname === "localhost" ||
      window.location.hostname === "127.0.0.1"
    ) {
      url = "http://localhost:5000/";
    } else {
      url = "https://api.digitalecmoacademy.com/";
    }
    const response = await fetch(`${url}api/v1/auth/me`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        token: localStorage.getItem("auth-token"),
      },
    });
    const data = await response.json();
    if (data.success) dispatch({ type: GET_USER_SUCCESS, payload: data.user });
    else {
      if (
        data.message === "jwt malformed" ||
        data.message === "jwt expired" ||
        data.message === "jwt not provided" ||
        data.message === "user not found"
      ) {
        localStorage.removeItem("auth-token");
        dispatch({ type: GET_USER_FAIL, payload: data.message });
        toast.error("Session expired, please login again.");
      } else {
        dispatch({ type: GET_USER_FAIL, payload: data.message });
      }
    }
  } catch (error) {
    dispatch({ type: GET_USER_FAIL, payload: error.message });
  }
};
