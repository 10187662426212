import { toast } from "react-toastify";
import {
  GET_LIST_FAILED,
  GET_LIST_REQUEST,
  GET_LIST_SUCCESS,
} from "../Constants/ListConstant";

export const getPlaylists = (playlists) => async (dispatch) => {
  if (playlists) {
    return dispatch({ type: GET_LIST_SUCCESS, payload: playlists });
  }
  try {
    let url = "";
    if (
      window.location.hostname === "localhost" ||
      window.location.hostname === "127.0.0.1"
    ) {
      url = "http://localhost:5000/";
    } else {
      url = "https://api.digitalecmoacademy.com/";
    }
    dispatch({ type: GET_LIST_REQUEST });
    const response = await fetch(
      `${url}api/v2/playlists`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem("token"),
        },
      }
    );
    const data = await response.json();
    if (data.success) {
      dispatch({ type: GET_LIST_SUCCESS, payload: data.playlists });
    } else {
      dispatch({ type: GET_LIST_FAILED, payload: data.message });
    }
  } catch (error) {
    dispatch({ type: GET_LIST_FAILED, payload: error.message });
  }
};
export const deletePlaylists = (id) => async (dispatch) => {
  try {
    dispatch({ type: GET_LIST_REQUEST });
    let url = "";
    if (
      window.location.hostname === "localhost" ||
      window.location.hostname === "127.0.0.1"
    ) {
      url = "http://localhost:5000/";
    } else {
      url = "https://api.digitalecmoacademy.com/";
    }
    const response = await fetch(
      `${url}api/v2/playlist`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "playlist-id": id,
          token: localStorage.getItem("auth-token"),
        },
      }
    );
    const data = await response.json();
    if (data.success) {
      toast.success(data.message);
      dispatch({ type: GET_LIST_SUCCESS, payload: data.playlists });
    } else {
      toast.error(data.message);
      dispatch({ type: GET_LIST_FAILED, payload: data.message });
    }
  } catch (error) {
    toast.error(error.message);
    dispatch({ type: GET_LIST_FAILED, payload: error.message });
  }
};
