import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useGlobalContext } from "../states/Context";
const LibCard = ({ title, url }) => {
  const { getApiUrl } = useGlobalContext();

  return (
    <div className="border my-4 border-gray-500 rounded-lg p-4 flex justify-between items-center">
      <h3 className="text-xl font-medium">{title}</h3>
      <div className="flex gap-4">
        <Link
          // target="_blank"
          to={`${getApiUrl()}static/uploads/docs/${url}`}
          className="border rounded-md border-blue-400 text-blue-400 px-4 py-2 text-sm"
        >
          READ
        </Link>
      </div>
    </div>
  );
};

export default LibCard;

LibCard.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};
