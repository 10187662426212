import { Fragment, useEffect, useRef, useState } from "react";
import AppLayout from "./utils/AppLayout";
import Input from "./utils/Input";
import { categories } from "./utils/Cat";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getPlaylists } from "../states/Actors/PlaylistActor";

import { Dialog, Transition } from "@headlessui/react";
import { FaCrown } from "react-icons/fa6";
import Loader from "./utils/Loader";
import { useGlobalContext } from "../states/Context";

const PlayLists = () => {
  const { getApiUrl } = useGlobalContext();
  const [open, setOpen] = useState(false);
  const cancelButtonRef = useRef(null);
  const [filter, setFilter] = useState("all");
  const { playlists, loading } = useSelector((state) => state.content);
  const { user } = useSelector((state) => state.account);
  const [filteredList, setFilteredList] = useState(playlists);
  const [query, setQuery] = useState("");
  const filterQuery = (e) => {
    setQuery(e.target.value);
    if (e.target.value === "") {
      setFilter("all");
      setFilteredList([]);
      return;
    }
    setFilter("");
    let newp = playlists.filter((p) => {
      return (
        p.title.toLowerCase().includes(e.target.value.toLowerCase()) ||
        p.description.toLowerCase().includes(e.target.value.toLowerCase()) ||
        p.category.toLowerCase().includes(e.target.value.toLowerCase())
      );
    });
    setFilteredList(newp);
  };
  const filterCategory = (cat) => {
    setFilter(cat);
    if (cat === "all") {
      return setFilteredList(playlists);
    }
    let newp = playlists.filter((p) => {
      return cat.toLowerCase() === p.category.toLowerCase();
    });
    setFilteredList(newp);
  };
  const setPlaylist = (p) => {
    setFilteredList(p);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getPlaylists());
    setPlaylist(playlists);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);
  return (
    <AppLayout>
    {loading ? (
        <Loader />
      ) : (
        <div>
          <div className="mt-12 mb-4">
            <Input
              value={query}
              handler={filterQuery}
              id={"query"}
              hint={`Search Videos by title, category or id`}
            />
          </div>
          <div className="mb-8 mt-12 flex flex-wrap gap-4">
            <button
              onClick={() => filterCategory("all")}
              className={`transition-all duration-200 bg-gray-700 rounded-md text-sm px-4 py-2 border-2 ${
                filter === "all" ? "border-gray-400" : "border-transparent"
              }`}
            >
              {" "}
              All
            </button>

            {categories.map((category, idx) => {
              return (
                <button
                  key={idx + category}
                  onClick={() => filterCategory(category)}
                  className={`transition-all duration-200 bg-gray-700 rounded-md text-sm px-4 py-2 border-2 ${
                    filter === category
                      ? "border-gray-400"
                      : "border-transparent"
                  }`}
                >
                  {" "}
                  {category}
                </button>
              );
            })}
          </div>
          {filteredList && filter !== "all" && (
            <section className="text-gray-400 bg-gray-900 body-font">
              <div className="container px-5 py-24 mx-auto">
                {filteredList.length > 0 ? (
                  <div className="flex flex-wrap -m-4">
                    {filteredList.map((p, idx) => {
                      return (
                        <div
                          key={p._id + idx}
                          className="lg:w-1/3 tilt-in-fwd-tr hover:scale-105 transition-all duration-200 md:w-1/2 p-4 w-full"
                        >
                          {p.isPremium && !user.isSubscribed ? (
                            <button onClick={() => setOpen(true)}>
                              <img
                                alt="ecommerce"
                                className="object-cover object-center w-full h-full block"
                                src={`${getApiUrl()}static/uploads/playlist/thumbnails/${p.thumbnail}`}
                              />
                            </button>
                          ) : (
                            <Link
                              to={`/videos/${p.slug}`}
                              className="block relative h-48 rounded overflow-hidden"
                            >
                              <img
                                alt="ecommerce"
                                className="object-cover object-center w-full h-full block"
                                src={`${getApiUrl()}static/uploads/playlist/thumbnails/${p.thumbnail}`}
                              />
                            </Link>
                          )}
                          <div className="mt-4">
                            <h3 className="text-gray-400 text-xs tracking-widest title-font mb-1">
                              {p?.category?.toUpperCase()}
                            </h3>
                            <h2 className="text-white title-font text-lg font-medium">
                              {p.title}
                            </h2>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div className="h-[30vh] flex items-center justify-center">
                    <h3 className="text-xl font-medium">Nothing Found</h3>
                  </div>
                )}
              </div>
            </section>
          )}
          {playlists && filter === "all" && query === "" && (
            <section className="text-gray-400 bg-gray-900 body-font">
              <div className="container px-5 py-24 mx-auto">
                {playlists.length > 0 ? (
                  <div className="flex flex-wrap -m-4">
                    {playlists.map((p, idx) => {
                      return (
                        <div
                          key={p._id + idx}
                          className="lg:w-1/3 tilt-in-fwd-tr hover:scale-105 transition-all duration-200 md:w-1/2 p-4 w-full"
                        >
                          {p.isPremium && !user.isSubscribed ? (
                            <button onClick={() => setOpen(true)}>
                              <img
                                alt="ecommerce"
                                className="object-cover object-center w-full h-full block"
                                src={`${getApiUrl()}static/uploads/playlist/thumbnails/${p.thumbnail}`}
                              />
                            </button>
                          ) : (
                            <Link
                              to={`/videos/${p.slug}`}
                              className="block relative h-48 rounded overflow-hidden"
                            >
                              <img
                                alt="ecommerce"
                                className="object-cover object-center w-full h-full block"
                                src={`${getApiUrl()}static/uploads/playlist/thumbnails/${p.thumbnail}`}
                              />
                            </Link>
                          )}
                          <div className="mt-4 flex justify-between items-center">
                            <div className="">
                              <h3 className="text-gray-400 text-xs tracking-widest title-font mb-1">
                                {p.category.toUpperCase()}
                              </h3>
                              <h2 className="text-white title-font text-lg font-medium">
                                {p.title}
                              </h2>
                            </div>
                            {p.isPremium && (
                              <div className="pr-4">
                                <FaCrown
                                  className="text-white text-lg
                            "
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div className="h-[30vh] flex items-center justify-center">
                    <h3 className="text-xl font-medium">Nothing Found</h3>
                  </div>
                )}
              </div>
            </section>
          )}
          <Transition.Root show={open} as={Fragment}>
            <Dialog
              as="div"
              className="relative z-10"
              initialFocus={cancelButtonRef}
              onClose={setOpen}
            >
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-black/20 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  >
                    <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                      <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                        <div className="sm:flex sm:items-start">
                          <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                            <FaCrown
                              className="h-6 w-6 text-yellow-600"
                              aria-hidden="true"
                            />
                          </div>
                          <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                            <Dialog.Title
                              as="h3"
                              className="text-base font-semibold leading-6 text-gray-900"
                            >
                              Premium Subscription
                            </Dialog.Title>
                            <div className="mt-2">
                              <p className="text-sm text-gray-500">
                                You haven&apos;t subscribed premium package yet
                                please subscribe to access this content.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                        <button
                          type="button"
                          className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                          onClick={() => setOpen(false)}
                          ref={cancelButtonRef}
                        >
                          close
                        </button>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition.Root>{" "}
        </div>
      )}
    </AppLayout>
  );
};

export default PlayLists;
