import { toast } from "react-toastify";
import AppLayout from "./utils/AppLayout";
import { useEffect, useState } from "react";
import Loader from "./utils/Loader";
import { useGlobalContext } from "../states/Context";

const Home = () => {
  const {getApiUrl} = useGlobalContext()
  const [home, setHome] = useState({});
  const [loading, setLoading] = useState(false);
  const getHome = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${getApiUrl()}api/v/home`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      if (!data.success) return toast.error(data.message);
      setHome(data.home);
    } catch (error) {
      toast.error(error.message);
    }
    setLoading(false);
  };
  useEffect(() => {
    getHome();
  }, []);
  return (
    <AppLayout>
      {loading ? (
        <Loader />
      ) : (
        <div className="my-12 h-[90vh]">
          <h1 className="text-4xl font-bold text-center">{home?.title}</h1>
          {home?.video_url ? (
            <div className="mt-16 mb-4">
              <video width="100%" controls>
                <source
                  src={`${getApiUrl()}static/uploads/videos/${home?.video_url}`}
                />
              </video>
            </div>
          ) : (
            ""
          )}
          <p className="relaxed mx-auto mt-12 text-center w-3/4">
            {home?.description}
          </p>
        </div>
      )}
    </AppLayout>
  );
};

export default Home;
