import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getUserInfo, logOutUser } from "../../states/Actors/UserActor";
import { FaUser } from "react-icons/fa6";
import { toast } from "react-toastify";

const Header = () => {
  const dispatch = useDispatch();
  const { isAuthenticated, user } = useSelector((state) => state.account);
  const nav = useNavigate();
  const [show, setShow] = useState(false);
  const logOut = () => {
    toast.success("logout successful");
    dispatch(logOutUser());
    nav("/login");
    localStorage.removeItem("auth-token");
  };
  useEffect(() => {
    if (localStorage.getItem("auth-token")) {
      dispatch(getUserInfo());
    }
  }, [dispatch]);
  return (
    <div className="flex justify-between py-4">
      <Link to={"/"}>
        <img src="/logo_main.jpeg" width={50} alt="" />
      </Link>
      <ul className="flex items-center gap-8">
        <li>
          <Link to={"/"}>Get Started</Link>
        </li>
        <li>
          <Link to={"/videos"}>Videos</Link>
        </li>
        <li>
          <Link to={"/libraries"}>Library</Link>
        </li>
      </ul>
      <div className="flex items-center gap-4">
        {isAuthenticated ? (
          <div className="relative">
            <button onClick={() => setShow(!show)}>
              <FaUser className="text-lg" />
            </button>
            {show && (
              <div className="absolute w-40 -right-4 mt-4 bg-gray-800 border border-gray-600 rounded-md">
                <ul>
                  <li className="p-2 hover:bg-gray-700 text-sm text-gray-300 cursor-pointer">
                    <Link
                      onClick={() => setShow(false)}
                      className="w-full block"
                      to="/user/profile"
                    >
                      Profile
                    </Link>
                  </li>
                  {user?.role === "admin" && (
                    <li className="p-2 hover:bg-gray-700 text-sm text-gray-300 cursor-pointer">
                      <Link
                        onClick={() => setShow(false)}
                        className="w-full block"
                        to="/admin/"
                      >
                        Dashboard
                      </Link>
                    </li>
                  )}
                  <li className="p-2 hover:bg-gray-700 text-sm text-gray-300 cursor-pointer">
                    <button
                      onClick={() => logOut()}
                      className="w-full block text-left"
                    >
                      Logout
                    </button>
                  </li>
                </ul>
              </div>
            )}
          </div>
        ) : (
          <Link
            to={"/login"}
            className="px-4 py-1 text-black bg-white font-medium rounded-full"
          >
            login
          </Link>
        )}
      </div>
    </div>
  );
};

export default Header;
