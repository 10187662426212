import { useEffect, useState } from "react";
import AdminLayout from "./AdminLayout";
import Input from "../utils/Input";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Loader from "../utils/Loader";
import { useGlobalContext } from "../../states/Context";

const EditHome = () => {
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const nav = useNavigate();
  const { getApiUrl } = useGlobalContext();
  const [fields, setFields] = useState({
    title: "",
    description: "",
    video_url: "",
  });
  const updateFields = async (e) => {
    e.preventDefault();
    const { description, title } = fields;
    if (description === "" || title === "") {
      return toast.error("please fill the fields");
    }
    setLoading(true);
    try {
      const response = await fetch(
        `${getApiUrl()}api/v/home`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            token: localStorage.getItem("auth-token"),
          },
          body: JSON.stringify(fields),
        }
      );
      const data = await response.json();
      if (data.success) {
        toast.success(data.message);
        setFields({
          title: "",
          description: "",
        });
        nav("/");
      } else toast.error(data.message);
    } catch (error) {
      toast.error(error.message);
    }
    setLoading(false);
  };
  const getHome = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${getApiUrl()}api/v/home`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            token: localStorage.getItem("auth-token"),
          },
        }
      );
      const data = await response.json();
      if (data.success) {
        setFields({
          title: data.home.title,
          description: data.home.description,
          video_url: data.home.video_url,
        });
      } else toast.error(data.message);
    } catch (error) {
      toast.error(error.message);
    }
    setLoading(false);
  };

  const onChange = (e) => {
    setFields({ ...fields, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    getHome();
  }, []);
  return (
    <AdminLayout>
      {loading ? (
        <Loader />
      ) : (
        <div className="w-4/5  gap-8 flex mx-auto my-24">
          <div className="w-1/2">
            <div className="mt-4 flex gap-4">
              {fields?.video_url !== "" && (
                <div className="mt-8">
                  <video
                    width="300"
                    height="250"
                    controls
                    muted
                    loop
                    className="w-[300px] object-cover mx-auto"
                  >
                    <source
                      src={`${getApiUrl()}static/uploads/videos/${fields?.video_url}`}
                      type="video/mp4"
                    ></source>
                  </video>
                </div>
              )}
            </div>
          </div>
          <div className="w-1/2">
            <h1 className="text-xl font-medium">Edit Home</h1>
            <form
              onSubmit={updateFields}
              encType="multipart/form-data"
              className="w-full gap-4 mt-8 items-center"
            >
              <div className="my-4 w-full">
                <label className="block mb-2 text-sm" htmlFor="thumbnail">
                  Title
                </label>
                <Input
                  hint={`Title`}
                  id={"title"}
                  type={`text`}
                  value={fields.title}
                  handler={onChange}
                />
              </div>
              <div className="my-4 w-full">
                <label className="block mb-2 text-sm" htmlFor="thumbnail">
                  Description
                </label>
                <Input
                  id={"description"}
                  hint={`Description`}
                  type={`text`}
                  value={fields.description}
                  handler={onChange}
                />
              </div>
              <div className="my-4 w-full">
                <label className="block mb-2 text-sm" htmlFor="video_url">
                  Video File Name
                </label>
                <Input
                  id={"video_url"}
                  hint={`Video File Name`}
                  type={`text`}
                  value={fields.video_url}
                  handler={onChange}
                />
              </div>
              <div className="mt-8 w-full">
                <Input
                  classes={`font-bold !bg-blue-400 hover:!bg-blue-500 cursor-pointer  disabled:opacity-50 disabled:cursor-not-allowed`}
                  type="submit"
                  value={`Update`}
                  disabled={
                    (fields.description.length === 0 ||
                      fields.title.length === 0) === null
                  }
                />
              </div>
            </form>
          </div>
        </div>
      )}
    </AdminLayout>
  );
};
export default EditHome;
