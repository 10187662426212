import { useNavigate, useParams } from "react-router-dom";
import AdminLayout from "./AdminLayout";
import Input from "../utils/Input";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import Loader from "../utils/Loader";
import { useGlobalContext } from "../../states/Context";
const EditDoc = () => {
  const { getApiUrl } = useGlobalContext();
  const { slug } = useParams();
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState({
    title: "",
  });
  const [selectedFile, setSelectedFile] = useState(null);
  const handleChange = (e) => {
    setFields({ ...fields, [e.target.name]: e.target.value });
  };

  const nav = useNavigate();
  const updateFields = async (e) => {
    e.preventDefault();
    const { description, title } = fields;
    if (description === "" || title === "") {
      return toast.error("please fill the fields");
    }
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("title", fields.title);
      formData.append("file", selectedFile);
      const response = await fetch(`${getApiUrl()}api/v4/library`, {
        method: "PUT",
        headers: {
          token: localStorage.getItem("auth-token"),
          "doc-slug": slug,
        },
        body: formData,
      });

      const data = await response.json();
      if (data.success) {
        toast.success(data.message);
        setFields({
          title: ""
        });
        nav(-1);
      } else toast.error(data.message);
    } catch (error) {
      toast.error(error.message);
    }
    setLoading(false);
  };
  const getDoc = async (slg) => {
    setLoading(true);
    try {
      const response = await fetch(`${getApiUrl()}api/v4/library`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem("auth-token"),
          "doc-slug": slg,
        },
      });
      const data = await response.json();
      if (data.success) {
        setFields({
          title: data.doc.title,
        });
      } else toast.error(data.message);
    } catch (error) {
      toast.error(error.message);
    }
    setLoading(false);
  };
  useEffect(() => {
    getDoc(slug);
  }, [slug]);
  return (
    <AdminLayout>
      {loading ? (
        <Loader />
      ) : (
        <div className="w-5/6 flex gap-14 justify-between mx-auto mt-24">
          <div className="w-1/2 mx-auto">
            <h2 className="text-xl font-medium text-center mb-8">
              Edit Document
            </h2>
            <form
              encType="multipart/form-data"
              onSubmit={updateFields}
              className="w-full mx-auto mt-8"
            >
              <div className="my-4">
                <label className="block mb-2 text-sm" htmlFor="category">
                  Title
                </label>
                <Input
                  type="text"
                  id="title"
                  hint={"title"}
                  autoComplete="off"
                  value={fields.title}
                  handler={handleChange}
                />
              </div>
              <div className="my-4">
                <label className="block mb-2 text-sm" htmlFor="file">
                  Doc
                </label>
                {selectedFile ? (
                  <p>
                    File Selected{" "}
                    <span className="text-gray-400">{selectedFile.name}</span>
                  </p>
                ) : (
                  <Input
                    id="file"
                    label="file"
                    type={"file"}
                    handler={(e) => setSelectedFile(e.target.files[0])}
                  />
                )}
              </div>{" "}
              <div className="mt-8">
                <Input
                  classes={`font-bold !bg-blue-400 hover:!bg-blue-500 cursor-pointer`}
                  type="submit"
                  value={`Update`}
                />
              </div>
            </form>
          </div>
        </div>
      )}
    </AdminLayout>
  );
};

export default EditDoc;
