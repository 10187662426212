import { useEffect, useState } from "react";
import AdminLayout from "./AdminLayout";
import { Link, useParams } from "react-router-dom";
import { FaPencil, FaTrash } from "react-icons/fa6";
import { toast } from "react-toastify";
import { AiOutlineVideoCameraAdd } from "react-icons/ai";
import Input from "../utils/Input";
import Loader from "../utils/Loader";
import { useGlobalContext } from "../../states/Context";

const Videos = () => {
  const { slug } = useParams();
  const [videos, setVideos] = useState([]);
  const { getApiUrl } = useGlobalContext();
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [video, setVideo] = useState({
    title: "",
    description: "",
    video_url: "",
  });
  const handleChange = (e) => {
    setVideo({ ...video, [e.target.name]: e.target.value });
  };
  const addVideo = async (e) => {
    e.preventDefault();
    const { description, title, video_url } = video;
    if (description === "" || title === "" || video_url === "") {
      return toast.error("please fill the fields");
    }
    setLoading(true);
    try {
      const response = await fetch(`${getApiUrl()}api/v3/video`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem("auth-token"),
          "playlist-slug": slug,
        },
        body: JSON.stringify(video),
      });

      const data = await response.json();
      if (data.success) {
        toast.success(data.message);
        setVideos(data.videos);
        setVideo({
          title: "",
          description: "",
          video_url: "",
        });
      } else toast.error(data.message);
    } catch (error) {
      toast.error(error.message);
    }
    setLoading(false);
  };
  const deleteVideo = async (id) => {
    setLoading(true);
    try {
      const response = await fetch(`${getApiUrl()}api/v3/video`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem("auth-token"),
          "video-id": id,
        },
      });

      const data = await response.json();
      if (data.success) {
        toast.success(data.message);
        setVideos(data.videos);
      } else toast.error(data.message);
    } catch (error) {
      toast.error(error.message);
    }
    setLoading(false);
  };
  const getPlaylistVideos = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${getApiUrl()}api/v3/videos`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "playlist-slug": slug,
          token: localStorage.getItem("auth-token"),
        },
      });
      const data = await response.json();
      if (data.success) {
        setName(data.name);
        setVideos(data.videos);
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    getPlaylistVideos();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug]);
  return (
    <AdminLayout>
      <div className="my-12 mx-8">
        <h3 className="text-2xl font-medium mb-8">{name}</h3>
        {loading ? (
          <Loader />
        ) : (
          <div className="flex gap-8">
            <div className="w-3/5  ">
              {videos?.length > 0 ? (
                videos.map((video) => {
                  return (
                    <div
                      key={video._id}
                      className="bg-gray-900 rounded-xl p-4 my-8 flex items-center justify-between"
                    >
                      <div className="flex gap-2 items-center ">
                        {video.thumbnail === undefined ? (
                          <small className="text-center">
                            No <br />
                            thumbnail
                          </small>
                        ) : (
                          <img
                            src={`${getApiUrl()}static/uploads/thumbnails/${
                              video.thumbnail
                            }`}
                            className="w-28 rounded-md"
                            alt=""
                          />
                        )}
                        <h3 className="text-xl font-normal">{video.title}</h3>
                      </div>
                      <div className="flex justify-center gap-4">
                        <Link
                          to={`/admin/video/edit/${video.slug}`}
                          className="text-gray-400"
                        >
                          <FaPencil className="text-xl" />
                        </Link>
                        <button
                          onClick={() => deleteVideo(video._id)}
                          type="button"
                          className="text-gray-400"
                        >
                          <FaTrash className="text-lg" />
                        </button>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="h-[40vh] flex items-center justify-center">
                  <div className="flex gap-2 py-4 px-6 bg-gray-800 border border-white rounded-md items-center">
                    <AiOutlineVideoCameraAdd className="text-2xl" />
                    <span>No Videos Yet</span>
                  </div>
                </div>
              )}
            </div>
            <div className="w-2/5">
              <h2 className="text-xl font-medium text-center mb-8">
                Add Video
              </h2>

              <form
                encType="multipart/form-data"
                // onSubmit={uploadProfile}
                onSubmit={addVideo}
                className="w-3/4 mx-auto mt-8"
              >
                <div className="my-4">
                  <label className="block mb-2 text-sm" htmlFor="category">
                    Title
                  </label>
                  <Input
                    type="text"
                    id="title"
                    hint={"title"}
                    autoComplete="off"
                    value={video.title}
                    handler={handleChange}
                  />
                </div>
                <div className="my-4">
                  <label className="block mb-2 text-sm" htmlFor="category">
                    Description
                  </label>
                  <Input
                    type="text"
                    id="description"
                    hint={"description"}
                    autoComplete="off"
                    value={video.description}
                    handler={handleChange}
                  />
                </div>
                <div className="my-4">
                  <label className="block mb-2 text-sm" htmlFor="video_url">
                    Video File Name
                  </label>
                  <Input
                    type="text"
                    id="video_url"
                    hint={"Video file name"}
                    autoComplete="off"
                    value={video.video_url}
                    handler={handleChange}
                  />
                </div>

                <div className="mt-8">
                  <Input
                    classes={`font-bold !bg-blue-400 hover:!bg-blue-500 cursor-pointer`}
                    type="submit"
                    value={`Add Video`}
                  />
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </AdminLayout>
  );
};

export default Videos;
