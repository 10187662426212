import { useParams } from "react-router-dom";
import AppLayout from "./utils/AppLayout";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Loader from "./utils/Loader";
import { useGlobalContext } from "../states/Context";

const Video = () => {
  const { getApiUrl } = useGlobalContext();
  const { slug } = useParams();
  const [video, setVideo] = useState({});
  const [loading, setLoading] = useState(false);
  const getVideo = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${getApiUrl()}api/v3/video`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "video-slug": slug,
          token: localStorage.getItem("auth-token"),
        },
      });
      const data = await response.json();
      if (data.success) {
        setVideo(data.video);
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    getVideo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug]);
  return (
    <AppLayout>
      {loading ? (
        <Loader />
      ) : (
        <div className="my-12">
          <div className="h3 mb-4 text-xl font-medium text-center">{video.title} </div>
          <div className="text-center">
            <video
              width="80%"
              className="mx-auto"
              height="250"
              controls
              muted
              loop
            >
              <source
                src={`${getApiUrl()}static/uploads/videos/${video.video_url}`}
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      )}
    </AppLayout>
  );
};

export default Video;
