import { MdDashboardCustomize, MdVideoSettings } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { MdLibraryAdd } from "react-icons/md";
import { MdLogout } from "react-icons/md";
import { AiOutlineVideoCameraAdd } from "react-icons/ai";
import { IoLibrary } from "react-icons/io5";
import { useDispatch } from "react-redux";
import { getUserInfo, logOutUser } from "../../states/Actors/UserActor";
import { FaUsers } from "react-icons/fa6";
import { useEffect } from "react";
import { toast } from "react-toastify";

const Menu = () => {
  const dispatch = useDispatch();
  const nav = useNavigate();
  const logOut = () => {
    toast.success("logout successful");
    dispatch(logOutUser());
    nav("/login");
    localStorage.removeItem("auth-token");
  };  useEffect(() => {
    if (localStorage.getItem("auth-token")) {
      dispatch(getUserInfo());
    }
  }, [dispatch]);
  return (
    <div className="w-1/6  h-full  bg-gray-900">
      <div className="">
        <Link
          to={"/"}
          className="text-blue-400 hover:text-blue-500 transition-all duration-200"
        >
          <img src="/logo.jpeg" width={100} className="mx-auto mt-8" alt="" />{" "}
        </Link>
        <ul className="mt-8">
          <li className="my-3">
            <Link
              to={"/admin"}
              className="flex gap-2 py-4 px-6 hover:bg-gray-700 items-center"
            >
              <MdDashboardCustomize className="text-2xl" />
              <span>Dashboard</span>
            </Link>
          </li>
          <li className="my-3">
            <Link
              to={"/admin/accounts"}
              className="flex gap-2 py-4 px-6 hover:bg-gray-700 items-center"
            >
              <FaUsers className="text-2xl" />
              <span>Accounts</span>
            </Link>
          </li>
          <li className="my-3">
            <Link
              to={"/admin/playlists"}
              className="flex gap-2 py-4 px-6 hover:bg-gray-700 items-center"
            >
              <MdVideoSettings className="text-2xl" />
              <span>Playlists</span>
            </Link>
          </li>
          <li className="my-3">
            <Link
              to={"/admin/libraries"}
              className="flex gap-2 py-4 px-6 hover:bg-gray-700 items-center"
            >
              <IoLibrary className="text-xl" />
              <span>Libraries</span>
            </Link>
          </li>
          <li className="my-3">
            <Link
              to={"/admin/playlist/create"}
              className="flex gap-2 py-4 px-6 hover:bg-gray-700 items-center"
            >
              <AiOutlineVideoCameraAdd className="text-2xl" />
              <span>Add Playlist</span>
            </Link>
          </li>
          <li className="my-3">
            <Link
              to={"/admin/library/create"}
              className="flex gap-2 py-4 px-6 hover:bg-gray-700 items-center"
            >
              <MdLibraryAdd className="text-2xl" />
              <span>Add Library</span>
            </Link>
          </li>
          <li className="my-3">
            <Link
              to={"/admin/home"}
              className="flex gap-2 py-4 px-6 hover:bg-gray-700 items-center"
            >
              <MdLibraryAdd className="text-2xl" />
              <span>Edit Home Page</span>
            </Link>
          </li>
          <li className="my-3">
            <button
              onClick={logOut}
              className="flex w-full gap-2 py-4 px-6 hover:bg-gray-700 items-center"
            >
              <MdLogout className="text-2xl" />
              <span>Logout</span>
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Menu;
