import { useEffect, useState } from "react";
import Input from "./utils/Input";
import { Link, useNavigate } from "react-router-dom";
import Loader from "./utils/Loader";
import bcryptjs from "bcryptjs";
import { toast } from "react-toastify";
import { FaEye, FaEyeSlash } from "react-icons/fa6";
import { useGlobalContext } from "../states/Context";

const SingUp = () => {
  const { getApiUrl } = useGlobalContext();
  const [user, setUser] = useState({
    username: "",
    phone: "",
    confirmPassword: "",
    password: "",
    email: "",
  });
  const [loading, setLoading] = useState(false);
  const [verified, setVerified] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");
  const nav = useNavigate();
  // Check if the verification code has expired
  function isVerificationCodeExpired() {
    const expirationTime = localStorage.getItem("ecmo-verify-code-expiry");
    if (!expirationTime) return true; // If there's no expiration time, consider it expired
    return new Date().getTime() > parseInt(expirationTime, 10);
  }
  const storeCode = async (code) => {
    // send email where the code will be sent
    const expirationTime = new Date().getTime() + 10 * 60 * 1000; // 10 minutes in milliseconds
    const hashedCode = await bcryptjs.hash(code.toString(), 10);

    localStorage.setItem("ecmo-verify-code", JSON.stringify(hashedCode));

    localStorage.setItem("ecmo-verify-code-expiry", expirationTime.toString());
  };
  const [signUp, setSignUp] = useState(false);
  const handleSingUp = async (e) => {
    e.preventDefault();
    if (
      user.email === "" ||
      user.firstName === "" ||
      user.lastName === "" ||
      user.phone === "" ||
      user.password === "" ||
      user.countryCode === ""
    ) {
      return toast.error("Please fill all the fields");
    }

    setLoading(true);
    try {
      const response = await fetch(
        `${getApiUrl()}api/v1/auth/register`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(user),
        }
      );
      const data = await response.json();
      if (data.success) {
        localStorage.setItem("ecmo-email", user.email);
        storeCode(data.code);
        setSignUp(true);
        setVerificationCode("");
        // nav("/login");
        toast.success(data.message);
      } else {
        toast.error(data.message);
        setSignUp(false);
      }
    } catch (error) {
      toast.error(error.message);
    }
    setLoading(false);
  };
  const onChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };
  const verifyCode = async (e) => {
    e?.preventDefault();
    if (verificationCode === "") {
      toast.error("enter code to verify");
      return;
    }
    let storedCode = localStorage.getItem("ecmo-verify-code");
    // No code to verify
    if (!storedCode) {
      toast.error("try to register again");
    }
    if (isVerificationCodeExpired()) {
      toast.error("code has expired! register again");
      localStorage.removeItem("ecmo-verify-code");
      localStorage.removeItem("ecmo-verify-code-expiry");
      return;
    } // Code has expired
    else {
      storedCode = JSON.parse(storedCode);
      let check = await bcryptjs.compare(verificationCode, storedCode);
      if (check) {
        setVerified(true);
        setVerificationCode("");
        localStorage.removeItem("ecmo-verify-code");
        localStorage.removeItem("ecmo-verify-code-expiry");
        const res = await fetch(
          `${getApiUrl()}api/v1/auth/verify`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              email: localStorage.getItem("ecmo-email") || user.email,
            }),
          }
        );
        const data = await res.json();
        if (data.success) {
          toast.success(data.message);
          nav("/login");
        } else {
          toast.error(data.message);
        }
        localStorage.removeItem("ecmo-email");
      } else {
        toast.error("code is incorrect");
      }
    }
  };
  const resendEmail = async () => {
    try {
      const response = await fetch(
        `${getApiUrl()}api/v1/auth/resend`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: localStorage.getItem("ecmo-email"),
          }),
        }
      );
      const data = await response.json();
      if (data.success) {
        storeCode(data.code);
        toast.success(data.message);
        setVerificationCode("");
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  const reEnterEmail = async () => {
    setSignUp(false);
    localStorage.removeItem("ecmo-verify-code");
    localStorage.removeItem("ecmo-email");
    localStorage.removeItem("ecmo-verify-code-expiry");
  };
  const [passType, setPassType] = useState("password");
  useEffect(() => {
    if (localStorage.getItem("auth-token")) {
      nav("/");
    }
    if (localStorage.getItem("ecmo-email")) {
      setSignUp(true);
      if (isVerificationCodeExpired()) {
        setSignUp(false);
        localStorage.removeItem("ecmo-verify-code");
        localStorage.removeItem("ecmo-email");
        localStorage.removeItem("ecmo-verify-code-expiry");
      }
    }
  }, [nav]);
  return (
    <div className="w-full relative bg-gray-900 flex items-center h-[100vh] justify-center mx-auto">
      <button className=" text-white absolute left-2 top-4">
        <Link to={"/"}>
          <img src="/logo_main.jpeg" width={100} className="mx-auto" alt="" />
        </Link>
      </button>
      <div className="flex w-2/5 flex-col justify-start">
        {" "}
        <h3 className="text-2xl text-center font-medium text-gray-200 mt-4 mb-4">
          Sign up for free
        </h3>
        {!signUp &&
          (loading ? (
            <Loader />
          ) : (
            <form onSubmit={handleSingUp} className="w-full py-2  mx-auto">
              <div className="my-4">
                <Input
                  value={user.email}
                  id={"email"}
                  hint={"Email"}
                  handler={onChange}
                  type={"email"}
                  classes={`!text-white`}
                />
              </div>
              <div className="my-4 flex gap-2">
                <Input
                  value={user.username}
                  id={"username"}
                  hint={"User Name"}
                  handler={onChange}
                  type={"text"}
                  classes={`w-1/2 !text-white`}
                />
                <Input
                  value={user.phone}
                  id={"phone"}
                  hint={"Phone"}
                  handler={onChange}
                  type={"tel"}
                  classes={`w-1/2 !text-white`}
                />
              </div>
              <div className="my-4 relative">
                <Input
                  value={user.password}
                  id={"password"}
                  hint={"Password"}
                  handler={onChange}
                  type={passType}
                  classes={`!text-white`}
                />{" "}
                {user.password.length > 0 && (
                  <button type="button" className="absolute right-3 top-5">
                    {passType === "text" ? (
                      <FaEyeSlash
                        className="cursor-pointer"
                        onClick={() => setPassType("password")}
                      />
                    ) : (
                      <FaEye
                        className="cursor-pointer"
                        onClick={() => setPassType("text")}
                      />
                    )}
                  </button>
                )}
              </div>
              <div className="my-4 relative">
                <Input
                  value={user.confirmPassword}
                  id={"confirmPassword"}
                  hint={"Confirm Password"}
                  handler={onChange}
                  type={passType}
                  classes={`!text-white`}
                />
                {user.password.length > 0 && (
                  <button type="button" className="absolute right-3 top-5">
                    {passType === "text" ? (
                      <FaEyeSlash
                        className="cursor-pointer"
                        onClick={() => setPassType("password")}
                      />
                    ) : (
                      <FaEye
                        className="cursor-pointer"
                        onClick={() => setPassType("text")}
                      />
                    )}
                  </button>
                )}
              </div>
              {/* <div className=" text-xs flex items-center justify-center text-gray-400 gap-1">
                <input type="checkbox" className="" name="" id="" /> I agree to
                the{" "}
                <Link
                  className="text-blue-400 border-b border-blue-400 hover:text-blue-500"
                  to="/login"
                >
                  Terms of Service
                </Link>
                and
                <Link
                  className="text-blue-400 border-b border-blue-400 hover:text-blue-500"
                  to="/login"
                >
                  Privacy Policy
                </Link>
              </div> */}

              <div className="mt-12">
                <Input
                  value={"Create account"}
                  type={"submit"}
                  classes={
                    "!bg-blue-400 hover:!bg-blue-500 cursor-pointer !py-4 !transition-all !duration-200 !text-white !border-0 !font-medium"
                  }
                />
              </div>
              <p className="mt-4 text-sm text-gray-400 text-center">
                Have an account{" "}
                <Link className="text-blue-400 hover:text-blue-500" to="/login">
                  Login
                </Link>
              </p>
            </form>
          ))}
        {signUp &&
          (loading ? (
            <Loader />
          ) : (
            <form onSubmit={verifyCode} className="w-full h-max py-2  mx-auto">
              <div className="my-4">
                <Input
                  value={verificationCode}
                  id={"verificationCode"}
                  hint={"Enter Six digit Code"}
                  handler={(e) => setVerificationCode(e.target.value)}
                  type={"text"}
                />
              </div>
              <div className="my-4">
                <Input
                  value={"VERIFY"}
                  type={"submit"}
                  classes={
                    "!bg-blue-400 hover:!bg-blue-500 cursor-pointer !py-4 !transition-all !duration-200 !text-white !border-0 !font-medium"
                  }
                />
              </div>
              {verified ? (
                <Link
                  to={"/login"}
                  className="text-blue-400 hover:text-blue-500 block mx-auto w-max"
                >
                  login
                </Link>
              ) : (
                <div className="flex gap-4">
                  <button
                    type="button"
                    onClick={resendEmail}
                    className="text-blue-400 text-base hover:text-blue-500 block mx-auto w-max"
                  >
                    resend
                  </button>
                  <button
                    type="button"
                    onClick={reEnterEmail}
                    className="text-blue-400 text-base hover:text-blue-500 block mx-auto w-max"
                  >
                    go back
                  </button>
                </div>
              )}
            </form>
          ))}
      </div>
    </div>
  );
};

export default SingUp;
