import { useEffect, useState } from "react";
import bcryptjs from "bcryptjs";
import Loader from "./utils/Loader";
import { Link, useNavigate } from "react-router-dom";
import Input from "./utils/Input";
import { toast } from "react-toastify";
import { useGlobalContext } from "../states/Context";

const Forgot = () => {
  const { getApiUrl } = useGlobalContext();
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState("");
  const [sent, setSent] = useState(false);
  const [email, setEmail] = useState("");
  const [verified, setVerified] = useState(false);
  const [confirmPass, setConfirmPass] = useState("");
  const [newPass, setNewPass] = useState("");

  const getResetLink = async (e) => {
    e?.preventDefault();
    setLoading(true);
    try {
      if (email === "" && !localStorage.getItem("ecmo-forgot-email")) {
        return toast.error("please fill all the fields");
      }

      const response = await fetch(`${getApiUrl()}api/v1/auth/pass/forgot`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });
      const data = await response.json();
      if (data.success) {
        storeCode(data.code);
        toast.success(data.message);
        setSent(true);
        localStorage.setItem("ecmo-forgot-email", email);
      } else {
        setSent(false);
        toast.error(data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
    setLoading(false);
  };

  const changePassword = async (e) => {
    e.preventDefault();
    try {
      if (newPass !== confirmPass) {
        toast.error("passwords do not match");
        return;
      }
      setLoading(true);
      const response = await fetch(`${getApiUrl()}api/v1/auth/pass/change`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: localStorage.getItem("ecmo-forgot-email"),
          password: newPass,
        }),
      });

      const data = await response.json();
      if (data.success) {
        setNewPass("");
        setConfirmPass("");
        localStorage.removeItem("ecmo-forgot-email");
        toast.success(data.message);
        nav("/login");
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      toast.error(error.message);
      setLoading(false);
    }
    setLoading(false);
  };
  const handleResend = async (e) => {
    e.preventDefault();
    if (!email || email === "" || !localStorage.getItem("ecmo-forgot-email")) {
      setSent(false);
      setEmail("");
    } else {
      getResetLink();
    }
  };

  // Check if the verification code has expired
  function isVerificationCodeExpired() {
    const expirationTime = localStorage.getItem("ecmo-forgot-code-expiry");
    if (!expirationTime) return true; // If there's no expiration time, consider it expired
    return new Date().getTime() > parseInt(expirationTime, 10);
  }
  const storeCode = async (code) => {
    // send email where the code will be sent
    const expirationTime = new Date().getTime() + 10 * 60 * 1000; // 10 minutes in milliseconds
    const hashedCode = await bcryptjs.hash(code, 10);
    localStorage.setItem("ecmo-forgot-code", JSON.stringify(hashedCode));

    localStorage.setItem("ecmo-forgot-code-expiry", expirationTime.toString());
  };
  // Verify the code
  const verifyCode = async (e) => {
    e.preventDefault();
    let storedCode = localStorage.getItem("ecmo-forgot-code");
    // No code to verify
    if (!storedCode) {
      toast.error("click on resend code");
    }
    if (isVerificationCodeExpired()) {
      toast.error("code has expired! click on resend code");
      localStorage.removeItem("ecmo-forgot-code");
      localStorage.removeItem("ecmo-forgot-code-expiry");
      localStorage.removeItem("ecmo-forgot-email");
      return;
    } // Code has expired
    else {
      storedCode = JSON.parse(storedCode);
      let check = await bcryptjs.compare(code.trim(), storedCode);
      if (check) {
        localStorage.removeItem("ecmo-forgot-code");
        localStorage.removeItem("ecmo-forgot-code-expiry");
        setVerified(true);
        toast.success("Code is correct! Now you can change your password");
      } else {
        toast.error("code is incorrect");
      }
    }
  };
  const nav = useNavigate();
  useEffect(() => {
    if (localStorage.getItem("ecmo-forgot-code")) {
      if (!isVerificationCodeExpired()) {
        setSent(true);
      } else {
        setSent(false);
      }
    }
  }, [nav]);
  return (
    <div className="w-full relative flex items-center justify-center bg-gray-900 mx-auto h-[100vh]">
      <button className=" text-white absolute left-2 top-4">
        <Link to={"/"}>
          <img src="/logo_main.jpeg" width={100} className="mx-auto" alt="" />
        </Link>
      </button>{" "}
      <div className="w-1/4 flex flex-col justify-center h-5/6">
        {!verified && loading ? (
          <Loader />
        ) : !verified && sent ? (
          <form onSubmit={verifyCode} className="w-full p-4 mx-auto">
            <div className="my-4">
              <Input
                value={code}
                id={"code"}
                hint={"Enter Six Digit Code"}
                handler={(e) => setCode(e.target.value)}
                classes={`!text-white`}
                type={"number"}
              />
            </div>
            <p className="my-2 text-sm text-gray-400 text-center">
              enter the six digit received code
            </p>
            <div className="my-4">
              <Input
                value={"VERIFY"}
                type={"submit"}
                classes={
                  "!bg-blue-400 hover:!bg-blue-500 cursor-pointer !py-4 !transition-all !duration-200 !text-white !border-0 !font-medium"
                }
              />
            </div>
            <button
              onClick={handleResend}
              type="button"
              className="mx-auto w-max block text-center text-blue-400 hover:text-blue-500"
            >
              resend code
            </button>
          </form>
        ) : (
          !verified && (
            <form onSubmit={getResetLink} className="w-full mx-auto">
              <div className="my-4">
                <Input
                  value={email}
                  id={"email"}
                  hint={"Email"}
                  handler={(e) => setEmail(e.target.value)}
                  type={"email"}
                  classes={`!text-white`}
                />
              </div>
              <p className="my-2 text-sm text-gray-400 text-center">
                enter your email to receive code
              </p>
              <div className="my-4">
                <Input
                  value={"GET CODE"}
                  type={"submit"}
                  classes={
                    "!bg-blue-400 hover:!bg-blue-500 cursor-pointer !py-4 !transition-all !duration-200 !text-white !border-0 !font-medium"
                  }
                />
              </div>
            </form>
          )
        )}
        {verified &&
          (loading ? (
            <Loader />
          ) : (
            <form onSubmit={changePassword} className="w-full mx-auto">
              <div className="my-4">
                <Input
                  value={newPass}
                  id={"newPass"}
                  hint={"New Password"}
                  handler={(e) => setNewPass(e.target.value)}
                  type={"password"}
                  classes={`!text-white`}
                />
              </div>
              <div className="my-4">
                <Input
                  value={confirmPass}
                  id={"confirmPass"}
                  hint={"Confirm Password"}
                  handler={(e) => setConfirmPass(e.target.value)}
                  type={"password"}
                  classes={`!text-white`}
                />
              </div>
              <div className="my-4">
                <Input
                  value={"CHANGE"}
                  type={"submit"}
                  classes={
                    "!bg-blue-400 hover:!bg-blue-500 cursor-pointer !py-4 !transition-all !duration-200 !text-white !border-0 !font-medium"
                  }
                />
              </div>
            </form>
          ))}
      </div>
    </div>
  );
};

export default Forgot;
