import Loader from "./utils/Loader";
import { useEffect, useState } from "react";
import AppLayout from "./utils/AppLayout";
import { toast } from "react-toastify";
import LibCard from "./LibCard";
import { useGlobalContext } from "../states/Context";

const Libraries = () => {
  const [loading, setLoading] = useState(false);
  const [libraries, setLibraries] = useState([]);
  const { getApiUrl } = useGlobalContext();
  const getDocs = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${getApiUrl()}api/v4/libraries`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      if (!data.success) {
        toast.error(data.message);
      }
      setLibraries(data.libraries);
    } catch (error) {
      toast.error(error.message);
    }
    setLoading(false);
  };
  useEffect(() => {
    getDocs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <AppLayout>
      <div className="my-24">
        {loading ? (
          <Loader />
        ) : libraries?.length > 0 ? (
          <div className="mb-8">
            {libraries.map((lib) => {
              return <LibCard url={lib.url} key={lib._id} title={lib.title} />;
            })}
          </div>
        ) : (
          <h1 className="text-xl font-medium text-center h-[50vh] flex items-center justify-center">
            No Libraries Found
          </h1>
        )}
      </div>
    </AppLayout>
  );
};

export default Libraries;
