import { Link, useParams } from "react-router-dom";
import AppLayout from "./utils/AppLayout";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Loader from "./utils/Loader";
import { useGlobalContext } from "../states/Context";

const Videos = () => {
  const { getApiUrl } = useGlobalContext();
  const { slug } = useParams();
  const [name, setName] = useState("");
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(false);
  const getPlaylistVideos = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${getApiUrl()}api/v3/videos`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "playlist-slug": slug,
            token: localStorage.getItem("auth-token"),
          },
        }
      );
      const data = await response.json();
      if (data.success) {
        setName(data.name);
        setVideos(data.videos);
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    getPlaylistVideos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug]);
  return (
    <AppLayout>
      {loading ? (
        <Loader />
      ) : (
        <div className="my-24">
          <h1 className="text-4xl font-bold text-center">{name}</h1>
          <div className="mt-4 flex w-full ">
            {videos.length > 0 ? (
              videos.map((video, idx) => {
                return (
                  <div
                    key={video._id + idx}
                    className="lg:w-1/3 tilt-in-fwd-tr hover:scale-105 transition-all duration-200 md:w-1/2 p-4 w-full"
                  >
                    <Link
                      to={`/videos/video/${video.slug}`}
                      className="block relative h-48 rounded overflow-hidden"
                    >
                      {video.thumbnail === "" ||
                      video.thumbnail === undefined ? (
                        <div className="h-full w-full flex items-center justify-center">
                          <h2 className="text-sm">No Thumbnail</h2>
                        </div>
                      ) : (
                        <img
                          alt="ecommerce"
                          className="object-cover object-center w-full h-full block"
                          src={`${getApiUrl()}static/uploads/thumbnails/${video.thumbnail}`}
                        />
                      )}
                      {/* <img
                        alt="ecommerce"
                        className="object-cover object-center w-full h-full block"
                        src={`${getApiUrl()}static/uploads/thumbnails/${video.thumbnail}`}
                      /> */}
                    </Link>
                    <div className="mt-4">
                      {/* <h3 className="text-gray-400 text-xs tracking-widest title-font mb-1">
                      {p.category.toUpperCase()}
                    </h3> */}
                      <h2 className="text-white title-font text-lg font-medium">
                        {video.title}
                      </h2>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="h-[30vh] w=max mx-auto  flex items-center justify-center">
                <h3 className="text-xl font-medium text-center">
                  No Videos Yet
                </h3>
              </div>
            )}
          </div>
        </div>
      )}
    </AppLayout>
  );
};

export default Videos;
