import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import AdminLayout from "./AdminLayout";
import Input from "../utils/Input";
import { categories } from "../utils/Cat";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "../utils/Loader";
import { useGlobalContext } from "../../states/Context";

const AddPlaylist = () => {
  const { getApiUrl } = useGlobalContext();
  const [playlist, setPlaylist] = useState({
    title: "",
    description: "",
    plan: "free",
    category: "--select--",
    file: null,
  });
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const handleChange = (e) => {
    setPlaylist({ ...playlist, [e.target.name]: e.target.value });
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  const addPlaylist = async (e) => {
    e.preventDefault();
    const { category, description, title } = playlist;
    if (category === "--select--" || description === "" || title === "") {
      return toast.error("please fill the fields");
    }
    // setPlaylist({ file: selectedFile });
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("title", playlist.title);
      formData.append("description", playlist.description);
      formData.append("category", playlist.category);
      formData.append("plan", playlist.plan);
      formData.append("file", selectedFile);
      const response = await fetch(`${getApiUrl()}api/v2/playlist`, {
        method: "POST",
        headers: {
          token: localStorage.getItem("auth-token"),
        },
        body: formData,
      });

      const data = await response.json();
      if (data.success) {
        toast.success(data.message);
        setSelectedFile(null);

        setPlaylist({
          title: "",
          description: "",
          category: "--select--",
          plan: "free",
          file: null,
        });
      } else toast.error(data.message);
    } catch (error) {
      toast.error(error.message);
    }
    setLoading(false);
  };
  const nav = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("auth-token")) return nav("/login");
  }, [nav]);
  return (
    <AdminLayout className="relative">
      <div className="w-2/5 mx-auto py-20">
        <h2 className="text-xl font-medium text-center mb-8">Add Playlist</h2>
        {loading ? (
          <Loader />
        ) : (
          <form
            encType="multipart/form-data"
            onSubmit={addPlaylist}
            className="w-full mt-8"
          >
            <div className="my-4">
              <label className="block mb-2 text-sm" htmlFor="category">
                Title
              </label>
              <Input
                type="text"
                id="title"
                hint={"title"}
                autoComplete="off"
                value={playlist.title}
                handler={handleChange}
              />
            </div>
            <div className="my-4">
              <label className="block mb-2 text-sm" htmlFor="category">
                Description
              </label>
              <Input
                type="text"
                id="description"
                hint={"description"}
                autoComplete="off"
                value={playlist.description}
                handler={handleChange}
              />
            </div>{" "}
            <div className="my-4">
              <label className="block mb-2 text-sm" htmlFor="category">
                Category
              </label>
              <select
                id="category"
                label="category"
                name="category"
                value={playlist.category}
                onChange={handleChange}
                className={`dark-glassmorphism  outline-none rounded-lg text-sm py-4 px-4 w-full transition-all duration-300 focus:border-1 focus:border-blue-400`}
              >
                <option className="bg-gray-800" value={"--select--"}>
                  -- Select Category --
                </option>
                {categories.map((category) => {
                  return (
                    <option
                      className="bg-gray-800"
                      key={category}
                      value={category}
                    >
                      {category}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="my-4">
              <label className="block mb-2 text-sm" htmlFor="plan">
                Plan
              </label>
              <select
                id="plan"
                label="plan"
                name="plan"
                value={playlist.plan}
                onChange={handleChange}
                className={`dark-glassmorphism  outline-none rounded-lg text-sm py-4 px-4 w-full transition-all duration-300 focus:border-1 focus:border-blue-400`}
              >
                <option className="bg-gray-800" key={"plan"} value={"free"}>
                  Free
                </option>
                <option
                  className="bg-gray-800"
                  key={"premium"}
                  value={"premium"}
                >
                  Premium
                </option>
              </select>
            </div>
            <div className="my-4">
              <label className="block mb-2 text-sm" htmlFor="thumbnail">
                Thumbnail
              </label>

              {/* <input type="file" onChange={handleFileChange} /> */}
            </div>
            {selectedFile ? (
              <p>
                File Selected{" "}
                <span className="text-gray-400">{selectedFile.name}</span>
              </p>
            ) : (
              <Input
                id="thumbnail"
                label="thumbnail"
                type={"file"}
                handler={handleFileChange}
              />
            )}
            <div className="mt-8">
              <Input
                classes={`font-bold !bg-blue-400 hover:!bg-blue-500 cursor-pointer`}
                type="submit"
                value={"Add Playlist"}
              />
            </div>
          </form>
        )}
      </div>
    </AdminLayout>
  );
};

export default AddPlaylist;
