import {
  GET_LIST_REQUEST,
  GET_LIST_SUCCESS,
  GET_LIST_FAILED,
} from "../Constants/ListConstant";
export const playlistReducer = (
  state = { playlists: [], loading: false, error: null },
  action
) => {
  switch (action.type) {
    case GET_LIST_REQUEST:
      return { ...state, loading: true, error: null };
    case GET_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        playlists: action.payload,
        error: null,
      };
    case GET_LIST_FAILED:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
