export const categories = [
    "ECMO Equipment",
    "ECMO Initiation/Cannulation",
    "ECMO Decannulation",
    "ECMO Troubleshooting",
    "Rotaflow",
    "CentriMag",
    "Cardiohelp",
    "ECPR"
];
