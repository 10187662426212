import { useNavigate, useParams } from "react-router-dom";
import AdminLayout from "./AdminLayout";
import Input from "../utils/Input";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Loader from "../utils/Loader";
import { useGlobalContext } from "../../states/Context";
const EditVideo = () => {
  const { slug } = useParams();
  const { getApiUrl } = useGlobalContext();
  const [loading, setLoading] = useState(false);
  const [video, setVideo] = useState({});
  const [fields, setFields] = useState({
    title: "",
    description: "",
    video_url: "",
  });
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const handleChange = (e) => {
    setFields({ ...fields, [e.target.name]: e.target.value });
  };

  const nav = useNavigate();
  const updateFields = async (e) => {
    e.preventDefault();
    const { description, title } = fields;
    if (description === "" || title === "") {
      return toast.error("please fill the fields");
    }
    setLoading(true);
    try {
      const response = await fetch(`${getApiUrl()}api/v3/video`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem("auth-token"),
          "video-slug": slug,
        },
        body: JSON.stringify(fields),
      });

      const data = await response.json();
      if (data.success) {
        toast.success(data.message);
        setVideo({
          title: "",
          description: "",
          video_url: "",
        });
        nav(-1);
      } else toast.error(data.message);
    } catch (error) {
      toast.error(error.message);
    }
    setLoading(false);
  };

  const updateThumbnail = async (e) => {
    e.preventDefault();
    if (!selectedImage) {
      return toast.error("please select image");
    }
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("file", selectedImage);

      const response = await fetch(`${getApiUrl()}api/v3/video/thumbnail`, {
        method: "PUT",
        headers: {
          token: localStorage.getItem("auth-token"),
          "video-slug": slug,
        },
        body: formData,
      });

      const data = await response.json();
      if (data.success) {
        toast.success(data.message);
        setSelectedImage(null);
        nav(-1);
      } else toast.error(data.message);
    } catch (error) {
      toast.error(error.message);
    }
    setLoading(false);
  };
  const getVideo = async (slg) => {
    setLoading(true);
    try {
      const response = await fetch(`${getApiUrl()}api/v3/video`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem("auth-token"),
          "video-slug": slg,
        },
      });
      const data = await response.json();
      if (data.success) {
        setVideo(data.video);
        setFields({
          title: data.video.title,
          description: data.video.description,
          video_url: data.video.video_url,
        });
      } else toast.error(data.message);
    } catch (error) {
      toast.error(error.message);
    }
    setLoading(false);
  };
  useEffect(() => {
    getVideo(slug);
  }, [slug]);
  return (
    <AdminLayout>
      {loading ? (
        <Loader />
      ) : (
        <div className="w-5/6 flex gap-14 justify-between mx-auto mt-24">
          <div className="w-1/2">
            <div className="mt-4 flex gap-4">
              {video?.video_url !== "" && (
                <div className="mt-8">
                  <video
                    width="300"
                    height="250"
                    controls
                    muted
                    loop
                    className="w-[300px] object-cover mx-auto"
                  >
                    <source
                      src={`${getApiUrl()}static/uploads/videos/${
                        video.video_url
                      }`}
                      type="video/mp4"
                    ></source>
                  </video>
                </div>
              )}
            </div>
            <div className="mt-4 flex gap-4">
              {video?.thumbnail !== "" && (
                <div className="mt-8">
                  <img
                    className="w-[300px] object-cover mx-auto"
                    src={`${getApiUrl()}static/uploads/thumbnails/${
                      video.thumbnail
                    }`}
                    alt=""
                  />
                </div>
              )}
              <form
                onSubmit={updateThumbnail}
                encType="multipart/form-data"
                className="w-1/2 gap-4 mt-8 items-center"
              >
                <div className="my-4 w-full">
                  <label className="block mb-2 text-sm" htmlFor="thumbnail">
                    Thumbnail
                  </label>

                  {/* <input type="file" onChange={handleFileChange} /> */}
                  {selectedImage ? (
                    <p>
                      File Selected{" "}
                      <span className="text-gray-400">
                        {selectedImage.name}
                      </span>
                    </p>
                  ) : (
                    <Input
                      id="thumbnail"
                      label="thumbnail"
                      type={"file"}
                      classes={``}
                      handler={(e) => setSelectedImage(e.target.files[0])}
                    />
                  )}
                </div>
                <div className="mt-8 w-full">
                  <Input
                    classes={`font-bold !bg-blue-400 hover:!bg-blue-500 cursor-pointer  disabled:opacity-50 disabled:cursor-not-allowed`}
                    type="submit"
                    value={`Update `}
                    disabled={selectedImage === null}
                  />
                </div>
              </form>
            </div>
          </div>
          <div className="w-1/2">
            <h2 className="text-xl font-medium text-center mb-8">Add Video</h2>
            <form
              encType="multipart/form-data"
              onSubmit={updateFields}
              className="w-full mx-auto mt-8"
            >
              <div className="my-4">
                <label className="block mb-2 text-sm" htmlFor="category">
                  Title
                </label>
                <Input
                  type="text"
                  id="title"
                  hint={"title"}
                  autoComplete="off"
                  value={fields.title}
                  handler={handleChange}
                />
              </div>
              <div className="my-4">
                <label className="block mb-2 text-sm" htmlFor="category">
                  Description
                </label>
                <Input
                  type="text"
                  id="description"
                  hint={"description"}
                  autoComplete="off"
                  value={fields.description}
                  handler={handleChange}
                />
              </div>{" "}
              <div className="my-4">
                <label className="block mb-2 text-sm" htmlFor="video_url">
                  Video File Name
                </label>
                <Input
                  type="text"
                  id="video_url"
                  hint={"Video file name"}
                  autoComplete="off"
                  value={fields.video_url}
                  handler={handleChange}
                />
              </div>{" "}
              <div className="mt-8">
                <Input
                  classes={`font-bold !bg-blue-400 hover:!bg-blue-500 cursor-pointer`}
                  type="submit"
                  value={`Update`}
                />
              </div>
            </form>
          </div>
        </div>
      )}
    </AdminLayout>
  );
};

export default EditVideo;
