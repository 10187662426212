import AdminLayout from "./AdminLayout";
import { MdAdminPanelSettings, MdVideoSettings } from "react-icons/md";
import { IoLibrary } from "react-icons/io5";
import { FaCrown, FaUsers } from "react-icons/fa6";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Loader from "../utils/Loader";
import { useGlobalContext } from "../../states/Context";
const Playlists = () => {
  const [loading, setLoading] = useState(false);
  const { getApiUrl } = useGlobalContext();
  const [stats, setStats] = useState({
    totalUsers: "",
    totalSubscribers: "",
    totalAdmins: "",
    totalFree: "",
    libraries: "",
    playlists: "",
  });
  const getStats = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${getApiUrl()}api/v4/admin/stats`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem("auth-token"),
        },
      });
      const data = await response.json();
      const {
        totalUsers,
        totalSubscribers,
        totalAdmins,
        totalFree,
        libraries,
        playlists,
      } = data;
      if (data.success) {
        setStats({
          totalUsers,
          totalSubscribers,
          totalAdmins,
          totalFree,
          libraries,
          playlists,
        });
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
    setLoading(false);
  };
  useEffect(() => {
    getStats();
  }, []);
  return (
    <AdminLayout>
      <section className="text-gray-400 body-font">
        <div className="container px-5 py-16 mx-auto">
          <div className="flex flex-col text-center w-full mb-8">
            <h1 className="text-2xl font-medium title-font mb-4 text-white">
              YOUR STATISTICS
            </h1>
          </div>

          {loading ? (
            <Loader />
          ) : (
            <div className="flex mx-auto flex-wrap justify-center -m-4 mt-24 gap-8">
              {stats?.playlists ? (
                <div className="lg:w-1/4 md:w-1/2 flex rounded-xl flex-col bg-gray-900 justify-center p-8 items-center">
                  <div className="flex gap-4">
                    <MdVideoSettings className="text-2xl text-white" />
                    <span className="text-lg">Your Playlists</span>
                  </div>
                  <span className="text-gray-300">{stats.playlists}</span>
                </div>
              ) : (
                ""
              )}
              {stats?.libraries ? (
                <div className="lg:w-1/4 md:w-1/2 flex rounded-xl flex-col bg-gray-900 justify-center p-8 items-center">
                  <div className="flex gap-4">
                    <IoLibrary className="text-2xl text-white" />
                    <span className="text-lg">Your Libraries</span>
                  </div>
                  <span className="text-gray-300">{stats.libraries}</span>
                </div>
              ) : (
                ""
              )}
              {stats?.totalSubscribers ? (
                <div className="lg:w-1/4 md:w-1/2 flex rounded-xl flex-col bg-gray-900 justify-center p-8 items-center">
                  <div className="flex gap-4">
                    <FaCrown className="text-2xl text-white" />
                    <span className="text-lg">Premium Users</span>
                  </div>
                  <span className="text-gray-300">
                    {stats.totalSubscribers}
                  </span>
                </div>
              ) : (
                ""
              )}
              {stats?.totalFree ? (
                <div className="lg:w-1/4 md:w-1/2 flex rounded-xl flex-col bg-gray-900 justify-center p-8 items-center">
                  <div className="flex gap-4">
                    <FaUsers className="text-2xl text-white" />
                    <span className="text-lg">Free Users</span>
                  </div>
                  <span className="text-gray-300">{stats.totalFree}</span>
                </div>
              ) : (
                ""
              )}{" "}
              {stats?.totalUsers ? (
                <div className="lg:w-1/4 md:w-1/2 flex rounded-xl flex-col bg-gray-900 justify-center p-8 items-center">
                  <div className="flex gap-4">
                    <FaUsers className="text-2xl text-white" />
                    <span className="text-lg">Total Users</span>
                  </div>
                  <span className="text-gray-300">{stats.totalUsers}</span>
                </div>
              ) : (
                ""
              )}{" "}
              {stats?.totalAdmins ? (
                <div className="lg:w-1/4 md:w-1/2 flex rounded-xl flex-col bg-gray-900 justify-center p-8 items-center">
                  <div className="flex gap-4">
                    <MdAdminPanelSettings className="text-2xl text-white" />
                    <span className="text-lg">Admins</span>
                  </div>
                  <span className="text-gray-300">{stats.totalAdmins}</span>
                </div>
              ) : (
                ""
              )}
            </div>
          )}
        </div>
      </section>
    </AdminLayout>
  );
};

export default Playlists;
