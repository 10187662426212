import {
  USER_LOGGED_IN,
  USER_LOGGED_OUT,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  GET_USER_FAIL,
} from "../Constants/UserConstant";

export const userReducer = (
  state = { user: {}, isAuthenticated: false, loading: true },
  action
) => {
  switch (action.type) {
    case USER_LOGGED_IN:
      return { ...state, user: action.payload, isAuthenticated: true };
    case USER_LOGGED_OUT:
      return { ...state, user: {}, isAuthenticated: false };
    case GET_USER_REQUEST:
      return { ...state, loading: true, isAuthenticated: false };
    case GET_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        isAuthenticated: true,
        user: action.payload,
      };
    case GET_USER_FAIL:
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
