import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./components/Home";
import Login from "./components/Login";
import SingUp from "./components/Signup";
import Verify from "./components/Verify";
import Forgot from "./components/Forgot";
import PlayLists from "./components/PlayLists.jsx";
import AdminPlayLists from "./components/Admin/Playlists.jsx";
import AppProvider from "./states/Context.jsx";
import { Provider } from "react-redux";
import store from "./states/store.jsx";
import Profile from "./components/Profile.jsx";
import Dashboard from "./components/Admin/Dashboard.jsx";
import AddPlaylist from "./components/Admin/AddPlaylist.jsx";
import AddLibrary from "./components/Admin/AddLibrary.jsx";
import AdminLibraries from "./components/Admin/Libraries.jsx";
import Libraries from "./components/Libraries.jsx";
import Videos from "./components/Videos.jsx";
import AdminVideos from "./components/Admin/Videos.jsx";
import EditVideo from "./components/Admin/EditVideo.jsx";
import Video from "./components/Video.jsx";
import Account from "./components/Admin/Account.jsx";
import EditDoc from "./components/Admin/EditDoc.jsx";
import EditPlaylist from "./components/Admin/EditPlaylist.jsx";
import EditHome from "./components/Admin/EditHome.jsx";
import NotFound from "./components/NotFound.jsx";
function App() {
  return (
    <Provider store={store}>
      <AppProvider>
        <Router>
          <ToastContainer
            position="bottom-center"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
          />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/*" element={<NotFound />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<SingUp />} />
            <Route path="/verify/:token" element={<Verify />} />
            <Route path="/password/forgot" element={<Forgot />} />
            <Route path="/videos" element={<PlayLists />} />
            <Route path="/videos/:slug" element={<Videos />} />
            <Route path="/videos/video/:slug" element={<Video />} />
            <Route path="/libraries" element={<Libraries />} />
            <Route path="/user/profile" element={<Profile />} />
            <Route path="/admin" element={<Dashboard />} />
            <Route path="/admin/playlist/create" element={<AddPlaylist />} />
            <Route path="/admin/library/create" element={<AddLibrary />} />
            <Route path="/admin/libraries" element={<AdminLibraries />} />
            <Route path="/admin/playlists" element={<AdminPlayLists />} />
            <Route path="/admin/playlist/:slug" element={<AdminVideos />} />
            <Route path="/admin/video/edit/:slug" element={<EditVideo />} />
            <Route path="/admin/accounts" element={<Account />} />
            <Route path="/admin/docs/:slug" element={<EditDoc />} />
            <Route path="/admin/playlist/edit/:slug" element={<EditPlaylist />} />
            <Route path="/admin/home" element={<EditHome />} />
          </Routes>
        </Router>
      </AppProvider>
    </Provider>
  );
}

export default App;
