import { createStore, combineReducers, applyMiddleware } from "redux";
import {thunk} from "redux-thunk";
import { composeWithDevTools } from "@redux-devtools/extension";
import { playlistReducer } from "./Reducers/PlaylistsReducer";
import { userReducer } from "./Reducers/User";

const initialState = {};

const reducer = combineReducers({
  content: playlistReducer,
  account:userReducer
});

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(thunk))
);

export default store;
