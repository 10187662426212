import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import AdminLayout from "./AdminLayout";
import Input from "../utils/Input";
import { categories } from "../utils/Cat";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../utils/Loader";
import { useGlobalContext } from "../../states/Context";
import { getPlaylists } from "../../states/Actors/PlaylistActor";
import { useDispatch } from "react-redux";

const EditPlaylist = () => {
  const { slug } = useParams();
  const { getApiUrl } = useGlobalContext();
  const [playlist, setPlaylist] = useState({
    title: "",
    description: "",
    plan: "free",
    category: "--select--",
    thumbnail: null,
  });
  const [selectedFile, setSelectedFile] = useState(null);
  const handleChange = (e) => {
    setPlaylist({ ...playlist, [e.target.name]: e.target.value });
  };

  const updatePlaylist = async (e) => {
    e.preventDefault();
    const { category, description, title } = playlist;
    if (category === "--select--" || description === "" || title === "") {
      return toast.error("please fill the fields");
    }
    setLoading(true);
    // setPlaylist({ file: selectedFile });
    try {
      let d = JSON.stringify(playlist);
      const response = await fetch(`${getApiUrl()}api/v2/playlist`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem("auth-token"),
          "playlist-slug": slug,
        },
        body: d,
      });

      const data = await response.json();
      if (data.success) {
        toast.success(data.message);
        setPlaylist({
          title: data.playlist.title,
          description: data.playlist.description,
          category: data.playlist.category,
          plan: data.playlist.plan,
          thumbnail: data.playlist.thumbnail,
        });
      } else toast.error(data.message);
    } catch (error) {
      toast.error(error.message);
    }
    setLoading(false);
  };
  const [loading, setLoading] = useState(false);
  const nav = useNavigate();
  const updateThumbnail = async (e) => {
    e.preventDefault();
    if (!selectedFile) {
      return toast.error("please select image");
    }
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("file", selectedFile);

      const response = await fetch(`${getApiUrl()}api/v2/playlist/thumbnail`, {
        method: "PUT",
        headers: {
          token: localStorage.getItem("auth-token"),
          "playlist-slug": slug,
        },
        body: formData,
      });

      const data = await response.json();
      if (data.success) {
        toast.success(data.message);
        setSelectedFile(null);
        nav(-1);
      } else toast.error(data.message);
    } catch (error) {
      toast.error(error.message);
    }
    setLoading(false);
  };
  const dispatch = useDispatch();
  const getPlaylist = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${getApiUrl()}api/v2/playlist`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem("auth-token"),
          "playlist-slug": slug,
        },
      });

      const data = await response.json();
      if (data.success) {
        setPlaylist({
          title: data.playlist.title,
          description: data.playlist.description,
          category: data.playlist.category,
          plan: data.playlist.plan,
          thumbnail: data.playlist.thumbnail,
        });
        dispatch(getPlaylists());
      } else toast.error(data.message);
    } catch (error) {
      toast.error(error.message);
    }
    setLoading(false);
  };
  useEffect(() => {
    getPlaylist();
  }, []);
  return (
    <AdminLayout className="relative">
      {loading ? (
        <Loader />
      ) : (
        <div className="w-5/6 mx-auto py-20 flex gap-8">
          <div className="w-1/2">
            <div className="mt-4 flex gap-4">
              {playlist?.thumbnail !== "" && (
                <div className="mt-8">
                  <img
                    className="w-[300px] object-cover mx-auto"
                    src={`${getApiUrl()}static/uploads/playlist/thumbnails/${
                      playlist?.thumbnail
                    }`}
                    alt=""
                  />
                </div>
              )}
              <form
                onSubmit={updateThumbnail}
                encType="multipart/form-data"
                className="w-1/2 gap-4 mt-8 items-center"
              >
                <div className="my-4 w-full">
                  <label className="block mb-2 text-sm" htmlFor="thumbnail">
                    Thumbnail
                  </label>

                  {/* <input type="file" onChange={handleFileChange} /> */}
                  {selectedFile ? (
                    <p>
                      File Selected{" "}
                      <span className="text-gray-400">{selectedFile.name}</span>
                    </p>
                  ) : (
                    <Input
                      id="thumbnail"
                      label="thumbnail"
                      type={"file"}
                      classes={``}
                      handler={(e) => setSelectedFile(e.target.files[0])}
                    />
                  )}
                </div>
                <div className="mt-8 w-full">
                  <Input
                    classes={`font-bold !bg-blue-400 hover:!bg-blue-500 cursor-pointer  disabled:opacity-50 disabled:cursor-not-allowed`}
                    type="submit"
                    value={`Update `}
                    disabled={selectedFile === null}
                  />
                </div>
              </form>
            </div>
          </div>
          <div className="w-1/2">
            <h2 className="text-xl font-medium text-center mb-8">
              Update Playlist
            </h2>
            <form
              encType="multipart/form-data"
              onSubmit={updatePlaylist}
              className="w-full mt-8"
            >
              <div className="my-4">
                <label className="block mb-2 text-sm" htmlFor="category">
                  Title
                </label>
                <Input
                  type="text"
                  id="title"
                  hint={"title"}
                  autoComplete="off"
                  value={playlist.title}
                  handler={handleChange}
                />
              </div>
              <div className="my-4">
                <label className="block mb-2 text-sm" htmlFor="category">
                  Description
                </label>
                <Input
                  type="text"
                  id="description"
                  hint={"description"}
                  autoComplete="off"
                  value={playlist.description}
                  handler={handleChange}
                />
              </div>{" "}
              <div className="my-4">
                <label className="block mb-2 text-sm" htmlFor="category">
                  Category
                </label>
                <select
                  id="category"
                  label="category"
                  name="category"
                  value={playlist.category}
                  onChange={handleChange}
                  className={`dark-glassmorphism  outline-none rounded-lg text-sm py-4 px-4 w-full transition-all duration-300 focus:border-1 focus:border-blue-400`}
                >
                  <option className="bg-gray-800" value={"--select--"}>
                    -- Select Category --
                  </option>
                  {categories.map((category) => {
                    return (
                      <option
                        className="bg-gray-800"
                        key={category}
                        value={category}
                      >
                        {category}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="my-4">
                <label className="block mb-2 text-sm" htmlFor="plan">
                  Plan
                </label>
                <select
                  id="plan"
                  label="plan"
                  name="plan"
                  value={playlist.plan}
                  onChange={handleChange}
                  className={`dark-glassmorphism  outline-none rounded-lg text-sm py-4 px-4 w-full transition-all duration-300 focus:border-1 focus:border-blue-400`}
                >
                  <option className="bg-gray-800" key={"plan"} value={"free"}>
                    Free
                  </option>
                  <option
                    className="bg-gray-800"
                    key={"premium"}
                    value={"premium"}
                  >
                    Premium
                  </option>
                </select>
              </div>
              <div className="mt-8">
                <Input
                  classes={`font-bold !bg-blue-400 hover:!bg-blue-500 cursor-pointer`}
                  type="submit"
                  value={"Update"}
                />
              </div>
            </form>
          </div>
        </div>
      )}
    </AdminLayout>
  );
};

export default EditPlaylist;
