import { useEffect, useState } from "react";
import { categories } from "../utils/Cat";
import AdminLayout from "./AdminLayout";
import Input from "../utils/Input";
import { FaEye, FaFilter, FaPencil, FaTrash } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  deletePlaylists,
  getPlaylists,
} from "../../states/Actors/PlaylistActor";
import Loader from "../utils/Loader";
import { MdVideoSettings } from "react-icons/md";
import { toast } from "react-toastify";
import { useGlobalContext } from "../../states/Context";

const Playlists = () => {
  const dispatch = useDispatch();
  const { getApiUrl } = useGlobalContext();
  const { loading, playlists } = useSelector((state) => state.content);
  const [filter, setFilter] = useState("all");
  const [show, setShow] = useState(false);
  const [filteredList, setFilteredList] = useState(playlists);
  const [query, setQuery] = useState("");
  const filterQuery = (e) => {
    setQuery(e.target.value);
    if (e.target.value === "" || query === "") {
      setFilteredList(playlists);
    }
    let newp = playlists.filter((p) => {
      return (
        p.title.toLowerCase().includes(e.target.value.toLowerCase()) ||
        p.description.toLowerCase().includes(e.target.value.toLowerCase()) ||
        p.category.toLowerCase().includes(e.target.value.toLowerCase())
      );
    });
    setFilteredList(newp);
  };
  const deletePlaylists = async (id) => {
    try {
      const response = await fetch(`${getApiUrl()}api/v2/playlist`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "playlist-id": id,
          token: localStorage.getItem("auth-token"),
        },
      });
      const data = await response.json();
      console.log(data);
      if (data.success) {
        toast.success(data.message);
        // setFilteredList(data.playlists)
        // dispatch(getPlaylists());
        window.location.reload();
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  const filterCategory = (cat) => {
    setShow(false);
    if (cat === "all") setFilteredList(playlists);
    setFilter(cat);
    let newp = playlists.filter((p) => {
      return cat.toLowerCase() === p.category.toLowerCase();
    });
    setFilteredList(newp);
  };
  const loadPlaylists = () => {
    dispatch(getPlaylists());
    if (playlists.length > 0) {
      setFilteredList(playlists);
    }
  };

  useEffect(() => {
    loadPlaylists();
  }, [dispatch]);
  return (
    <AdminLayout>
      <section className="text-gray-400 body-font">
        <div className="container px-5 py-16 mx-auto">
          <div className="flex flex-col text-center w-full mb-8">
            <h1 className="text-2xl font-medium title-font mb-4 text-white">
              YOUR PLAYLISTS
            </h1>
            <p className="lg:w-2/3 mx-auto leading-relaxed text-base">
              add videos, edit playlists, view videos or delete them
            </p>
          </div>
          <div className="mt-4 mb-4 flex items-center gap-4">
            <Input
              classes={`!w-11/12`}
              handler={filterQuery}
              value={query}
              id={"query"}
              hint={`Search Videos by title, category or id`}
            />
            <div className="relative w-1/12 ">
              <button
                onClick={() => setShow(true)}
                className="bg-gray-900 w-full flex gap-2  px-4 py-3 rounded-md items-center"
              >
                <FaFilter className="text-white" />
                <span className="text-white "> Filters</span>
              </button>
              {show && (
                <div className="absolute bg-gray-900 w-60 right-0 rounded-md top-16">
                  <ul>
                    <li>
                      <button
                        onClick={() => filterCategory("all")}
                        className={`transition-all text-left duration-200 w-full hover:bg-gray-800 rounded-md text-sm px-4 py-2 border-0 ${
                          filter === "all"
                            ? "bg-gray-700 text-white"
                            : "bg-transparent"
                        }`}
                      >
                        {" "}
                        All
                      </button>
                    </li>
                    {categories.map((category, idx) => {
                      return (
                        <li key={idx + category}>
                          <button
                            onClick={() => filterCategory(category)}
                            className={`transition-all text-left duration-200 w-full hover:bg-gray-800 text-sm px-4 py-2 border-0 ${
                              filter === category
                                ? "bg-gray-600 text-white"
                                : "bg-transparent"
                            }`}
                          >
                            {" "}
                            {category}
                          </button>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )}
            </div>
          </div>
          {loading ? (
            <Loader />
          ) : (
            playlists &&
            filteredList.length <= 0 &&
            query === "" && (
              <div className="flex flex-wrap -m-4 mt-8">
                {playlists.map((p, idx) => {
                  return (
                    <div key={p._id + idx} className="p-4 lg:w-1/4 md:w-1/2">
                      <div className="h-full flex flex-col items-center text-center">
                        <img
                          alt="team"
                          className="flex-shrink-0 rounded-lg w-full h-56 object-cover object-center mb-4"
                          src={`${getApiUrl()}static/uploads/playlist/thumbnails/${
                            p.thumbnail
                          }`}
                        />
                        <div className="w-full">
                          <h2 className="title-font font-medium text-lg text-white">
                            {p.title}
                          </h2>
                          <h3 className="text-gray-500 mb-3">{p.category}</h3>
                          <p className="mb-4">{p.description} </p>
                          <div className="flex justify-center gap-4">
                            <Link
                              to={`/admin/playlist/${p.slug}`}
                              className="text-gray-400"
                            >
                              <FaEye className="text-xl" />
                            </Link>
                            <Link
                              to={`/admin/playlist/edit/${p.slug}`}
                              className="text-gray-400"
                            >
                              <FaPencil className="text-xl" />
                            </Link>
                            <button
                              onClick={() => deletePlaylists(p._id)}
                              className="text-gray-400"
                            >
                              <FaTrash className="text-lg" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )
          )}
          {!loading && filteredList.length > 0 && (
            <div className="flex flex-wrap -m-4 mt-8">
              {filteredList.map((p, idx) => {
                return (
                  <div key={p._id + idx} className="p-4 lg:w-1/4 md:w-1/2">
                    <div className="h-full flex flex-col items-center text-center">
                      <img
                        alt="team"
                        className="flex-shrink-0 rounded-lg w-full h-56 object-cover object-center mb-4"
                        src={`${getApiUrl()}static/uploads/playlist/thumbnails/${
                          p.thumbnail
                        }`}
                      />
                      <div className="w-full">
                        <h2 className="title-font font-medium text-lg text-white">
                          {p.title}
                        </h2>
                        <h3 className="text-gray-500 mb-3">{p.category}</h3>
                        <p className="mb-4">{p.description} </p>
                        <div className="flex justify-center gap-4">
                          <Link
                            to={`/admin/playlist/${p.slug}`}
                            className="text-gray-400"
                          >
                            <FaEye className="text-xl" />
                          </Link>
                          <Link
                            to={`/admin/playlist/edit/${p.slug}`}
                            className="text-gray-400"
                          >
                            <FaPencil className="text-xl" />
                          </Link>
                          <button
                            onClick={() => deletePlaylists(p._id)}
                            className="text-gray-400"
                          >
                            <FaTrash className="text-lg" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          {!loading && filteredList.length === 0 && playlists.length === 0 && (
            <div className="h-[40vh] w-full flex justify-center items-center">
              <Link
                to={"/admin/playlist/create"}
                className="flex gap-2 py-4 px-6 bg-gray-800 border border-white rounded-md items-center"
              >
                <MdVideoSettings className="text-2xl" />
                <span>Add Playlist</span>
              </Link>
            </div>
          )}
        </div>
      </section>
    </AdminLayout>
  );
};

export default Playlists;
