import { useContext, createContext, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const AppContext = createContext();

// eslint-disable-next-line react/prop-types
const AppProvider = ({ children }) => {
  const { playlists } = useSelector((state) => state.content);
  const [loading, setLoading] = useState(false);
  const [filteredPlayLists, setFilteredPlayLists] = useState(playlists);
  const [alert, setAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [alertType, setAlertType] = useState("success");
  const [lectures, setLectures] = useState([]);
  const [courseName, setCourseName] = useState("");
  const [filter, setFilter] = useState("all");
  const [show, setShow] = useState(false);
  const [filteredList, setFilteredList] = useState(playlists);
  const [query, setQuery] = useState("");
  const filterQuery = (e) => {
    setQuery(e.target.value);
    if (e.target.value === "" || query === "") {
      setFilteredList(playlists);
    }
    let newp = playlists.filter((p) => {
      return (
        p.title.toLowerCase().includes(e.target.value.toLowerCase()) ||
        p.description.toLowerCase().includes(e.target.value.toLowerCase()) ||
        p.category.toLowerCase().includes(e.target.value.toLowerCase())
      );
    });
    setFilteredList(newp);
  };
  const filterCategory = (cat) => {
    setShow(false);
    if (cat === "all") setFilteredList(playlists);
    setFilter(cat);
    let newp = playlists.filter((p) => {
      return cat.toLowerCase() === p.category.toLowerCase();
    });
    setFilteredList(newp);
  };

  const getCourseLectures = async (id) => {
    const response = await fetch(
      `${getApiUrl()}api/v3/lectures`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "course-id": id,
        },
      }
    );
    const data = await response.json();
    if (data.success) {
      setCourseName(data.courseName);
      setLectures(data.lectures);
    } else {
      toast.error(data.message);
    }
  };
  // CRUD OPERATIONS for course @params id
  const getApiUrl = () => {
    const { hostname } = window.location;
    if (hostname === "localhost" || hostname === "127.0.0.1") {
      return "http://localhost:5000/";
    } else {
      return "https://api.digitalecmoacademy.com/";
    }
  };
  return (
    <AppContext.Provider
      value={{
        getApiUrl,
        playlists,
        getCourseLectures,
        filter,
        setFilter,
        setFilteredList,
        filteredList,
        lectures,
        setLectures,
        loading,
        filterCategory,
        filterQuery,
        setLoading,
        filteredPlayLists,
        setFilteredPlayLists,
        alert,
        setAlert,
        alertMsg,
        setAlertMsg,
        alertType,
        setAlertType,
        courseName,
        show,
        setShow,
        setCourseName,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useGlobalContext = () => {
  return useContext(AppContext);
};

export default AppProvider;
