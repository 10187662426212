import { Fragment, useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserInfo, logOutUser } from "../states/Actors/UserActor";
import AppLayout from "./utils/AppLayout";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { FaEye, FaEyeSlash } from "react-icons/fa6";
import Input from "./utils/Input";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import Loader from "./utils/Loader";
import { useGlobalContext } from "../states/Context";
const stripePromise = loadStripe(
  "pk_test_51Mj6h1SEr7VMOboEVZUJQo5IzeYg929DQY3Ch2e34HcHQnLaQUv34zGpIJlrGHEuipYeJwT1jBOYInyJ8jwFW7Yr001sY6CokF"
);

const Profile = () => {
  const {getApiUrl} = useGlobalContext()
  const [open, setOpen] = useState(false);

  const cancelButtonRef = useRef(null);
  const dispatch = useDispatch();
  const [password, setPassword] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const changeHandler = (e) => {
    setPassword({ ...password, [e.target.name]: e.target.value });
  };
  const [loading, setLoading] = useState(false);
  const nav = useNavigate();
  const updatePassword = async (e) => {
    e.preventDefault();
    const { oldPassword, newPassword, confirmPassword } = password;
    if (oldPassword === "" || newPassword === "" || confirmPassword === "")
      return toast.error(`Please Input Passwords`);

    if (newPassword !== confirmPassword)
      return toast.error(`New Password and Confirm password should match`);
    setLoading(true);

    let token = localStorage.getItem("auth-token");
    if (!token) {
      setLoading(false);
      return toast.error("please re login");
    }
    let d = JSON.stringify({
      oldPassword,
      newPassword,
    });
    try {
      const res = await fetch(
        `${getApiUrl()}api/v1/auth/password`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            token,
          },
          body: d,
        }
      );

      const data = await res.json();

      if (data.success) {
        setPassword({
          oldPassword: "",
          newPassword: "",
          confirmPassword: "",
        });
        toast.success(`${data.message}`, {
          autoClose: 1500,
        });
        dispatch(logOutUser());
        nav("/login");
      } else {
        toast.error(data.message, {
          autoClose: 1500,
        });
      }
    } catch (error) {
      toast.error(error.message);
    }
    setLoading(false);
  };
  const handlePay = async () => {
    setLoading(true);
    const response = await fetch(
      `${getApiUrl()}api/v6/payment/checkout_session`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem("auth-token"),
        },
      }
    );
    const data = await response.json();
    const session = data.session;
    (await stripePromise).redirectToCheckout({
      sessionId: session.id,
    });

    setLoading(false);
    // window.location.assign(data);
  };
  const updateSubscription = async () => {
    setLoading(true);
    setOpen(false);
    try {
      const response = await fetch(
        `${getApiUrl()}api/v1/auth/subscribe`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            token: localStorage.getItem("auth-token"),
          },
        }
      );
      const data = await response.json();
      if (data.success) {
        toast.success(data.message);
      } else {
        toast.error(data.message);
      }
      dispatch(getUserInfo());
    } catch (error) {
      toast.error(error.message);
    }
    setLoading(false);
  };
  const [passType, setPassType] = useState("password");
  const [newPassType, setNewPassType] = useState("password");
  const { user } = useSelector((state) => state.account);
  useEffect(() => {
    if (!localStorage.getItem("auth-token")) return nav("/login");
    dispatch(getUserInfo());

    const search = new URLSearchParams(window.location.search);
    let alert = true;
    if (search.get("subscription") === "success" && alert) {
      alert = false;
      updateSubscription();
      nav("/user/profile");
    }
    if (search.get("subscription") === "failed") {
      toast.error("Subscription Failed");
      setTimeout(() => {
        nav("/user/profile");
      }, 3000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);
  return (
    <AppLayout>
      {loading ? (
        <Loader />
      ) : (
        <div className="my-24">
          <div className="mb-8 flex gap-8 items-start">
            <div className="w-1/4">
              <img
                src="/logo_main.jpeg"
                width={180}
                height={180}
                className="rounded-full mx-auto"
                alt=""
              />
            </div>

            <div className="w-3/4">
              <div className="px-4 sm:px-0">
                <h3 className="text-base font-semibol mt-0 mb-2 leading-5 text-gray-200">
                  Profile Information
                </h3>
                <p className="mt-1 max-w-2xl text-sm leading-3 text-blue-400">
                  Personal details.
                </p>
              </div>
              <div className="mt-6">
                <dl className="divide-y divide-gray-400">
                  <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt className="text-sm font-medium leading-3 text-gray-200">
                      User Name
                    </dt>
                    {user?.username ? (
                      <dd className="mt-1 text-sm leading-3 text-blue-400 sm:col-span-2 sm:mt-0">
                        {user?.username}
                      </dd>
                    ) : (
                      <dd className="mt-1 text-sm leading-3 text-blue-400 sm:col-span-2 sm:mt-0">
                        Not Available
                      </dd>
                    )}
                  </div>
                  <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt className="text-sm font-medium leading-3 text-gray-200">
                      Email
                    </dt>
                    {user?.email ? (
                      <dd className="mt-1 text-sm leading-3 text-blue-400 sm:col-span-2 sm:mt-0">
                        {user?.email}
                      </dd>
                    ) : (
                      <dd className="mt-1 text-sm leading-3 text-blue-400 sm:col-span-2 sm:mt-0">
                        Not Available
                      </dd>
                    )}
                  </div>
                  <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt className="text-sm font-medium leading-3 text-gray-200">
                      Contact
                    </dt>
                    {user?.phone ? (
                      <dd className="mt-1 text-sm leading-3 text-blue-400 sm:col-span-2 sm:mt-0">
                        {user?.phone}
                      </dd>
                    ) : (
                      <dd className="mt-1 text-sm leading-3 text-blue-400 sm:col-span-2 sm:mt-0">
                        Not Available
                      </dd>
                    )}
                  </div>
                  <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt className="text-sm font-medium leading-3 text-gray-200">
                      Account Verification
                    </dt>
                    {user?.isVerified ? (
                      <dd className="mt-1 text-sm leading-3 text-blue-400 sm:col-span-2 sm:mt-0">
                        Verified
                      </dd>
                    ) : (
                      <dd className="mt-1 text-sm leading-3 text-blue-400 sm:col-span-2 sm:mt-0">
                        Un Verified
                      </dd>
                    )}
                  </div>
                  <div className="px-4 py-6 items-center sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt className="text-sm font-medium leading-3 text-gray-200">
                      Premium
                    </dt>
                    <dd className="mt-2 text-sm text-gray-200 sm:col-span-2 sm:mt-0">
                      {user?.isSubscribed ? (
                        <button
                          onClick={() => setOpen(true)}
                          className=" bg-blue-500 text-white  transition-all duration-200 px-2 py-1 rounded-md text-sm"
                        >
                          Un Subscribe
                        </button>
                      ) : (
                        <button
                          onClick={handlePay}
                          className="border-2 border-blue-500  text-blue-400 hover:text-white hover:bg-blue-500 transition-all duration-200 px-2 py-1 rounded-md text-sm"
                        >
                          {" "}
                          Subscribe
                        </button>
                      )}
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
          </div>
          <form onSubmit={updatePassword} className="mt-12">
            <div className="px-4 sm:px-0 mb-8">
              <h3 className="text-base font-semibol mt-0 mb-2 leading-5 text-gray-200">
                Change Password
              </h3>
            </div>
            <div className="relative w-3/5">
              <Input
                handler={changeHandler}
                classes={`!w-full`}
                id={"oldPassword"}
                type={passType}
                value={password.oldPassword}
                hint={"Old Password"}
              />
              {password.oldPassword.length > 0 && (
                <button type="button" className="absolute right-3 top-5">
                  {passType === "text" ? (
                    <FaEyeSlash
                      className="cursor-pointer"
                      onClick={() => setPassType("password")}
                    />
                  ) : (
                    <FaEye
                      className="cursor-pointer"
                      onClick={() => setPassType("text")}
                    />
                  )}
                </button>
              )}
            </div>
            <div className="flex gap-8 mt-4 w-full">
              <div className="relative w-full">
                <Input
                  handler={changeHandler}
                  classes={`!w-w-full`}
                  value={password.newPassword}
                  hint={"New Password"}
                  id={"newPassword"}
                  type={newPassType}
                />
                {password.newPassword.length > 0 && (
                  <button type="button" className="absolute right-3 top-5">
                    {newPassType === "text" ? (
                      <FaEyeSlash
                        className="cursor-pointer"
                        onClick={() => setNewPassType("password")}
                      />
                    ) : (
                      <FaEye
                        className="cursor-pointer"
                        onClick={() => setNewPassType("text")}
                      />
                    )}
                  </button>
                )}
              </div>
              <div className="relative w-full">
                <Input
                  handler={changeHandler}
                  classes={`!w-full`}
                  hint={"Confirm New Password"}
                  id={"confirmPassword"}
                  type={newPassType}
                />
                {password.confirmPassword.length > 0 && (
                  <button type="button" className="absolute right-3 top-5">
                    {newPassType === "text" ? (
                      <FaEyeSlash
                        className="cursor-pointer"
                        onClick={() => setNewPassType("password")}
                      />
                    ) : (
                      <FaEye
                        className="cursor-pointer"
                        onClick={() => setNewPassType("text")}
                      />
                    )}
                  </button>
                )}
              </div>
            </div>
            <div className="flex mt-8 items-center justify-end">
              <Input
                type={"submit"}
                value={"Update"}
                classes={`!w-max !bg-blue-400 hover:!bg-blue-500 !border-0 !cursor-pointer !py-2`}
              />
            </div>
          </form>
        </div>
      )}
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/20 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                      <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                        <ExclamationTriangleIcon
                          className="h-6 w-6 text-red-600"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-base font-semibold leading-6 text-gray-900"
                        >
                          Cancel Subscription
                        </Dialog.Title>
                        <div className="mt-2">
                          <p className="text-sm text-gray-500">
                            Are you sure you want to cancel your premium
                            subscription? once it is cancel you can&apos;t
                            refund amount paid. this action cannot be undone.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                      onClick={() => updateSubscription()}
                    >
                      Yes
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => setOpen(false)}
                      ref={cancelButtonRef}
                    >
                      No
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </AppLayout>
  );
};

export default Profile;
