import { useEffect, useState } from "react";

import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Loader from "./utils/Loader";
import { toast } from "react-toastify";
import Input from "./utils/Input";
import { FaEye, FaEyeSlash } from "react-icons/fa6";
import { userActor } from "../states/Actors/UserActor";
import { useGlobalContext } from "../states/Context";

const Login = () => {
  const { getApiUrl } = useGlobalContext();
  const [user, setUser] = useState({ loginId: "", password: "" });
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const nav = useNavigate();
  const handleLogin = async (e) => {
    e.preventDefault();
    if (user.loginId === "" || user.password === "") {
      toast.error("please fill all the fields");
      return;
    }
    setLoading(true);

    try {
      const response = await fetch(`${getApiUrl()}api/v1/auth/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(user),
      });
      const data = await response.json();
      if (data.success) {
        localStorage.setItem("auth-token", data.token);
        nav("/");
        dispatch(userActor(data.user));
        toast.success(data.message);
      } else {
        toast.error(data.message);
      }
      setLoading(false);
    } catch (error) {
      toast.error(error.message);
    }
    setLoading(false);
  };
  const [passType, setPassType] = useState("password");
  const onChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    if (localStorage.getItem("auth-token")) {
      nav("/");
    }
  }, [nav]);

  return (
    <div className="w-full relative flex items-center justify-center h-[100vh] bg-gray-900">
      <button className=" text-white absolute left-2 top-4">
        <Link to={"/"}>
          <img src="/logo_main.jpeg" width={100} className="mx-auto" alt="" />
        </Link>
      </button>
      <div className="flex flex-col justify-center w-1/4">
        <h3 className="text-2xl text-center font-medium text-gray-200 mt-12 mb-4">
          Login
        </h3>
        {loading ? (
          <Loader />
        ) : (
          <form onSubmit={handleLogin} className="w-full py-2 mx-auto">
            <div className="my-4">
              <Input
                value={user.loginId}
                id={"loginId"}
                hint={"Email or Username"}
                handler={onChange}
                type={"text"}
              />
            </div>
            <div className="my-4 relative">
              <Input
                value={user.password}
                id={"password"}
                hint={"Password"}
                handler={onChange}
                type={passType}
              />
              {user.password.length > 0 && (
                <button type="button" className="absolute right-3 top-5">
                  {passType === "text" ? (
                    <FaEyeSlash
                      className="cursor-pointer"
                      onClick={() => setPassType("password")}
                    />
                  ) : (
                    <FaEye
                      className="cursor-pointer"
                      onClick={() => setPassType("text")}
                    />
                  )}
                </button>
              )}
            </div>
            <p className="my-2 text-sm text-gray-700 text-center">
              <Link
                className="text-blue-400 hover:text-blue-500"
                to="/password/forgot"
              >
                Forgot Password?
              </Link>
            </p>
            <div className="my-4">
              <Input
                value={"LOGIN"}
                type={"submit"}
                classes={
                  "!bg-blue-400 hover:!bg-blue-500 cursor-pointer !py-4 !transition-all !duration-200 !text-white !border-0 !font-medium"
                }
              />
            </div>
            <p className="my-2 text-sm text-gray-400 text-center">
              Don&apos;t have an account{" "}
              <Link
                className="text-blue-400 hover:text-blue-500"
                to="/register"
              >
                Sign Up
              </Link>
            </p>
          </form>
        )}
      </div>
    </div>
  );
};

export default Login;
